import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentfulConfigService {
  summaryRegExp: any;
  summaryValueToReplace: string;
  contactPage: string;
  clickThroughJourney: string;
  holdingsDashboard: string;

  constructor() {
    this.contactPage = '3kKs9MuLCcysMENqHIdBeC';
    this.clickThroughJourney = '4KDpGqVmEm5zSv8CwSXtv5';
    this.summaryRegExp = new RegExp(/<p><\/p>/, 'g');
    this.summaryValueToReplace = '<br/>';
    this.holdingsDashboard = '3LWAhbpczZ4sAgsqxabiKv';
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathComputationService {

  constructor() { }

  roundNumber(number, devideBy, decimalPoints) {
    let powerOfTen = Math.pow(10, decimalPoints);
    return Number((Math.round((number / devideBy) * powerOfTen) / powerOfTen).toFixed(decimalPoints)).toLocaleString('en', { minimumFractionDigits: decimalPoints });
  }

  filterChartDataBeforeSpecificYear(data: any) {
    return data.filter((d: any) => new Date(d.reportingPeriod).getFullYear() > 2022)
  }
}

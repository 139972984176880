import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { IStepOption } from 'ngx-ui-tour-md-menu';

@Injectable({
    providedIn: 'root'
})
export class NgTourService {
    private steps$ = new ReplaySubject(null);

    constructor() {
    }

    setTourSteps(steps: IStepOption[]) {
        this.steps$.next(steps);
    }

    getTourSteps(): Observable<any> {
        return this.steps$.asObservable();
    }
}
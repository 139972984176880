import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { Observable } from 'rxjs';
import { GenericRepository } from './generic.repository';
import { InvestorMeta } from 'src/app/models/classes/investor-meta.class';

@Injectable({ providedIn: 'root' })
export class InvestorRepository extends GenericRepository<InvestorMeta> {

  constructor(apiService: ApiService) {
    super(apiService, 'investors');
    this.apiService.getInvestors().subscribe((investors: InvestorMeta[]) => {
      this.clearEntities();
      this.setAllEntities(investors);
    })
  }

  fetchEntityFromDataSource(id: number): Observable<InvestorMeta> {
    throw Error(`Invalid investor id ${id}`)
  }
}
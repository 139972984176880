<app-base-widget [error]="sectorByRemainingMarketValue?.error" data-cy="sectorsWidget">
    <div header class="card-header d-flex"> Sector
        <div>
            <app-info-icon tooltipPosition="above" tooltipInfo="GICS Sector Classifications for underlying assets"
                data-cy="sectorsTooltip">
            </app-info-icon>
        </div>
    </div>
    <span class="by-text" data-cy="byText">By Remaining Market Value</span>
    <gcm-chart [chartData]="sectorByRemainingMarketValue" [chartStyle]="chartStyle" chartType="bar"
        [chartOptions]="sectorByRemainingMarketValueChartOptions"></gcm-chart>
</app-base-widget>
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {

  constructor(public notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  isTemplate(notification) { return notification.textOrTpl instanceof TemplateRef; }

}

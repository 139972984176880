import { Injectable } from '@angular/core';
import { CsvModes } from '../core/csv-modes.class';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportCsvService {

  constructor() { }

  getExportFilename(mode, asOfDateString, portfolioName, seriesName, holdingName?) {
    var asOfDate = new Date(asOfDateString.replace(/-/g, '\/'))
    let asofdate = (asOfDate.getMonth() + 1) + '-' + asOfDate.getDate() + '-' + asOfDate.getFullYear();
    let currentDateobj = new Date();
    let currentDate = (currentDateobj.getMonth() + 1) + '-' + currentDateobj.getDate() + '-' + currentDateobj.getFullYear();
    let fileName = '';
    if (mode === CsvModes.holdingAssets) {
      fileName = mode + "_" + portfolioName + "_" + seriesName + "_" + holdingName + "_as of_" + asofdate + "_" + currentDate;
    }
    else {
      fileName = mode + "_" + portfolioName + "_" + seriesName + "_as of_" + asofdate + "_" + currentDate;
    }
    return fileName;
  }

  getCsvHeader(mode) {
    if (mode === CsvModes.quarterlyRollForward) {
      return {
        'invType': 'Investment Type', 'dealName': 'Deal Name', 'holdingName': 'Holding Name', 'invStatus': 'Investment Status',
        'holdingPriorNav': 'Prior Quarter Net Asset Value', 'holdingQoqContributions': 'QoQ Contribution',
        'holdingQoqDistributions': 'QoQ Distributions', 'holdingGainLoss': 'Gain/Loss', 'holdingCurrentNav': 'Current Quarter Net Asset Value', 'holdingQoqTvpiChange': 'QoQ Change TVPI', 'holdingQoqIrrChange': 'QoQ Change IRR',
      }
    }
    else if (mode === CsvModes.qRollForwardInvestmentType) {
      return {
        'invType': 'Investment Type', 'invTypePriorNav': 'Investment Type Prior Quarter Net Asset Value ',
        'invTypeQoqContributions': 'Investment Type QoQ Contribution', 'invTypeQoqDistributions': 'Investment Type QoQ Distributions', 'invTypeGainLoss': 'Investment Type Gain/Loss', 'invTypeCurrentNav': 'Investment Type Current Quarter Net Asset Value',
        'invTypeQoqTvpiChange': 'Investment Type QoQ Change TVPI', 'invTypeQoqIrrChange': 'Investment Type QoQ Change IRR'
      }
    }
    else if (mode === CsvModes.InvestmentType) {
      return {
        'invType': 'Investment Type', 'invTypeCommitment': 'Commitment', 'invTypeReportedContributions': 'Reported Contributions', 'invTypeContributions': 'Contributions', 'invTypeRemainingCommitment': 'Remaining Commitment',
        'invTypeReportedDistributions': 'Reported Distributions', 'invTypeDistributions': 'Distributions', 'invTypeReportedValue': 'Reported Value', 'invTypeSubsequentContributions': 'Subsequent Contributions',
        'invTypeSubsequentDistributions': 'Subsequent Distributions', 'invTypeFxGainLoss': 'FX Gain/(Loss)', 'invTypeNav': 'Net Asset Value', 'invTypeTotalValue': 'Total Value', 'invTypeTvpi': 'TVPI', 'invTypeIrr': 'IRR'
      }
    }
    else if (mode === CsvModes.seriesHolding) {
      return {
        'invType': 'Investment Type', 'dealName': 'Deal Name', 'holdingName': 'Holding Name', 'invStatus': 'Investment Status', 'strategy': 'Strategy', 'vintageYear': 'Vintage Year', 'valuationDateFormatted': 'Valuation Date',
        'holdingCommitment': 'Commitment', 'holdingReportedContributions': 'Reported Contributions', 'holdingContributions': 'Contributions', 'holdingRemainingCommitment': 'Remaining Commitment', 'holdingReportedDistributions': 'Reported Distributions',
        'holdingDistributions': 'Distributions', 'holdingReportedValue': 'Reported Value', 'holdingSubsequentContributions': 'Subsequent Contributions', 'holdingSubsequentDistributions': 'Subsequent Distributions', 'holdingFxGainLoss': 'FX Gain/(Loss)',
        'holdingNav': 'Net Asset Value', 'holdingTotalValue': 'Total Value', 'holdingTVPI': 'TVPI', 'holdingIRR': 'IRR',
      }
    }
    else {
      return {
        'assetName': 'Asset Name', 'aquisitionDate': 'Aquisition Date', 'remainingCost': 'Remaining Cost Basis', 'remainingMarketValue': 'Remaining Market Value', 'statementDate': 'Statement Date', 'sector': 'Sector',
        'subIndustry': 'Sub-Industry', 'geography': 'Geography', 'country': 'Country', 'status': 'Status', 'exitDate': 'Exit Date'
      }
    }
  }

  sortArrayAscending(arr) {
    return arr.sort((a, b) => a.name.localeCompare(b.name));
  }

  exportToExcelFile(filename, worksheets) {
    let sheets = {};
    let sheetNames = []
    worksheets.forEach(worksheet => {
      sheets[worksheet.name] = XLSX.utils.json_to_sheet(worksheet.csvArray, { skipHeader: true });
      sheetNames.push(worksheet.name)
    })
    const workBook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: sheetNames
    }
    XLSX.writeFile(workBook, filename + '.xlsx', { bookType: 'xlsx', type: 'array' });
  }
}

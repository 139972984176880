import { Component, Input } from '@angular/core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-investor-summary',
  templateUrl: './investor-summary.component.html',
  styleUrls: ['./investor-summary.component.scss']
})
export class InvestorSummaryComponent {
  @Input() investorSummary;
  @Input() iconSet: Record<string, string> = {};
  @Input() showBanner;
  investorId: string;

  constructor( 
    private tourService: TourService,
    private userService: UserService,
    private activityTracking: ActivityTrackingService
  ) {}

  ngOnInit(): void {
    let showTutorial = !this.userService.getTutorialComplete();
    if (this.investorSummary && showTutorial) {
      this.activityTracking.tourServiceStarted(false);
      this.tourService.start()
    }
  }
}

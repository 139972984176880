import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { EnvironmentVariablesService } from './environment-variables.service';
import { LoggingService } from './logging.service';
import { ConstantQueryService } from './constant-query.service';

@Injectable({
  providedIn: 'root'
})

export class ContentfulService {
  // To store key for contentful without calling API multiple times
  private contentfulKey$: ReplaySubject<any> = new ReplaySubject(1);
  baseURL: string;
  proxyService: any;
  developer: string;
  cfEndPoint: string;
  landingPageList: any = ['dashboard', 'series', 'holdings', 'assets', 'contactPage','clickThroughJourney'];
  IconsetQueryProxy: string;
  private iconSetValue = new BehaviorSubject(null);
  private termsValue = new BehaviorSubject(null);

  constructor(private apollo: Apollo, private http: HttpClient, private evService: EnvironmentVariablesService, private loggingService: LoggingService, private graphqlquery: ConstantQueryService) {
    this.baseURL = this.evService.proxy['hostname'] + '/' + this.evService.proxy['apis'].Contentful;
    this.cfEndPoint = 'https://graphql.contentful.com/content/v1/spaces/' + this.evService.contentful.spaceId + '/environments/' + this.evService.contentful['environmentMode'];
  }

  getPageNotFound(previewFlag: Boolean): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: this.graphqlquery.pageNotFoundQuery,
      variables: {
        previewFlag: previewFlag,
      },
      errorPolicy: 'all'
    }).valueChanges;
  }

  getLoginPage(previewFlag: Boolean): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: this.graphqlquery.loginLandingPage,
      variables: {
        previewFlag: previewFlag,
      },
      errorPolicy: 'all'
    }).valueChanges;
  }

  proxyErrorHandler(error: HttpErrorResponse) {
    this.loggingService.log("ContentFul API Proxy Error - - ", error);
    return throwError(() => error || "server error.");
  }

  initializeContentfulKey() {
    this.http.get(this.baseURL + "/apikey", {
      responseType: 'text'
    }).pipe(catchError(x => this.proxyErrorHandler(x))).subscribe(res => {
      this.contentfulKey$.next(res);
    })
  }

  fetchCommonAssets(mode: string, authToken: string, id: string = ''): Observable<any> {
    var query = '';
    var variables = {};
    var switchMode = (this.landingPageList.indexOf(mode) < 0) ? mode : 'landingPage';

    let data = this.getAssetQueryAndVariable(switchMode, id);
    query = data.query;
    variables = data.variables;

    let assets$: Observable<any> = this.contentfulKey$.pipe(
      switchMap((cfKey): any => {
        return this.http.post(this.cfEndPoint,
          JSON.stringify({ query: query, variables: variables }),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'authorization': 'Bearer ' + cfKey
            })
          })
          .pipe(catchError(x => this.proxyErrorHandler(x)));
      })
    )
    return assets$
  }

  buildKeyValueLabels(array) {
    let labelsArray = {};
    for (let arid = 0; arid < array.length; arid++) {
      labelsArray[array[arid]['key']] = array[arid]['value'];
    }
    return labelsArray;
  }

  buildKeyValueIcons(array) {
    let iconsSet = {};
    for (let arid = 0; arid < array.length; arid++) {
      if (array[arid].iconImage == null) {
        iconsSet[array[arid]['key']] = '';
      }
      else {
        iconsSet[array[arid]['key']] = array[arid].iconImage['url'];
      }
    }
    return iconsSet;
  }

  getResourceValue(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i].value;
      }
    }
    return null;
  }

  getAssetQueryAndVariable(assetType: string, id: any) {
    let assetQueryAndVariableArr = [{ assetType: 'header', query: this.graphqlquery.headerQuery, variables: { previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'iconUrl', query: this.graphqlquery.IconSetQuery, variables: { previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'terms', query: this.graphqlquery.termsConditionsQuery, variables: { previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'session', query: this.graphqlquery.sessionTimeOutQuery, variables: { previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'landingPage', query: this.graphqlquery.landingPageByIDQuery, variables: { id: id, previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'holdingSummary', query: this.graphqlquery.HoldingSummaryQuery, variables: { masterId: Number(id), previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'investmentManager', query: this.graphqlquery.InvestmentManagerQuery, variables: { masterId: Number(id), previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'assetSummary', query: this.graphqlquery.AssetSummaryQuery, variables: { masterId: Number(id), previewFlag: this.evService.contentful.previewFlag } },
    { assetType: 'sidebar', query: this.graphqlquery.navigationQuery, variables: { previewFlag: this.evService.contentful.previewFlag } }]

    let assetData = assetQueryAndVariableArr.filter(x => x.assetType === assetType)[0];
    return assetData;
  }

  getExternalRedirectIcon(previewFlag: Boolean): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: this.graphqlquery.externalRedirectIconQuery,
      variables: {
        previewFlag: previewFlag,
      },
      errorPolicy: 'all'
    }).valueChanges;
  }

  setIconSetValue(val: any) {
    this.iconSetValue.next(this.buildKeyValueIcons(val?.data?.iconCollection?.items));
  }

  setTermsValue(tncDetails: any) {
    this.termsValue.next(tncDetails);
  }

  getIconSetValue(): Observable<any> {
    return this.iconSetValue.asObservable();
  }

  getTermsValue(): Observable<any> {
    return this.termsValue.asObservable();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { ContentfulService } from 'src/app/services/contentful.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent implements OnInit {
  logoutIconUrl: string = '';

  constructor(
    private userService: UserService,
    private ContentfulService: ContentfulService,
    private activityTracking: ActivityTrackingService
  ) {} 

  ngOnInit(): void {
    this.getContentfulData();
  }

  getContentfulData(): void {
    this.ContentfulService.fetchCommonAssets('header', '').subscribe((result: any) => {
      this.logoutIconUrl = result?.data?.header.iconsCollection.items[0].iconImage.url;
    });
  }

  logoutClick(): void {
    this.activityTracking.logout();
    this.userService.logoutApp();
  }
}

<header class="topbar">
    <nav
        class="navbar-shadow flex items-center justify-between flex-wrap bg-white p-[5px] lg:pt-[14px] lg:px-[5px] lg:pb-[8px] mx-[-15px]">
        <div>
            <a *ngIf="!checkCurrentInvestorId()" class="navbar-brand pointer" data-cy="GCMHeaderLogo">
                <img class="h-[24px] lg:h-[41px]" src="{{evService.assetsFolderPath + '/images/GCM-logo-blue.svg'}}" alt="GCM" />
                <img class="clientScopeLogo h-[24px] lg:h-[41px]" src="{{evService.assetsFolderPath + '/images/Client-scope-logo.svg'}}" alt="Client Scope" />
            </a>
            <a *ngIf="checkCurrentInvestorId()" class="navbar-brand pointer" routerLinkActive="active" routerLink="/dashboard" 
            [queryParams]="getDashboardQueryParams()" (click)="setActiveInvestor()" data-cy="GCMHeaderLogo">
                <img class="h-[24px] lg:h-[41px]" src="{{evService.assetsFolderPath + '/images/GCM-logo-blue.svg'}}" alt="GCM" />
                <img class="clientScopeLogo h-[24px] lg:h-[41px]" src="{{evService.assetsFolderPath + '/images/Client-scope-logo.svg'}}" alt="Client Scope" />
            </a>
        </div>
        <div class="tour-icon" title="Click this icon to activate guided dashboard tour" (click)="tourService.start(); activityTracking.tourServiceStarted(true)">
        </div>
        <mat-icon class="lg:hidden" (click)="showSideNav()">menu</mat-icon>

        <div class="col align-items-center hidden lg:flex">
            <ul class="navbar-nav ml-auto flex align-items-center">
                <app-investor-dropdown data-cy="investorDropdown"></app-investor-dropdown>
                <li ngbDropdown class="nav-item dropdown ">
                    <div class="flex dropdown-wrap justify-content-center  align-items-center " id="navbarDropdown"
                        role="button" aria-haspopup="true" aria-expanded="false">
                        <a class="nav-link  dropdown-toggle pl-[15px]" ngbDropdownToggle data-cy="userDropdown">
                            <small>Welcome,</small>
                            <br>
                            {{ displayName }}
                        </a>
                        <div class="avatar">
                            <p class="avatar-inner">{{displayNameInitials}}</p>
                        </div>
                    </div>
                    <div class="dropdown-menu top-[-80px] right-[-15px]" ngbDropdownMenu
                        aria-labelledby="navbarDropdown">
                        <app-logout-button></app-logout-button>
                    </div>
                </li>
                <li class="nav-item"></li>
            </ul>
        </div>
    </nav>
</header>
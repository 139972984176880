//This file code has been taken from below mentioned links
// https://github.com/8Tesla8/angular-responsive-design/tree/main/src/app/responsive
// https://medium.com/geekculture/how-to-make-responsive-design-in-angular-1ad9d936dc16
export enum OrientationType {
    Unknown = 'Unknown',
    Portrait = 'Portrait',
    Landscape = 'Landscape',
}

export enum DeviceType {
    Unknown = 'Unknown',
    Tablet = 'Tablet',
    Handset = 'Handset', //Mobile
    Web = 'Web',
}

export enum ScreenSizeType {
    Unknown = 'Unknown',
    XSmall = 'XSmall',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    XLarge = 'XLarge',
}

export enum BreakpointType {
    Unknown = 'Unknown',

    HandsetPortrait = 'HandsetPortrait',
    HandsetLandscape = 'HandsetLandscape',

    TabletPortrait = 'TabletPortrait',
    TabletLandscape = 'TabletLandscape',

    WebPortrait = 'WebPortrait',
    WebLandscape = 'WebLandscape',
}
import { Injectable, TemplateRef } from '@angular/core';
import { EnvironmentVariablesService } from 'src/app/services/environment-variables.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notifications: any[] = [];

  constructor(
    private environmentService: EnvironmentVariablesService
  ) {}

  showErrorNotification(error: any) {
    if (this.environmentService.notificationConfig['showNotification']) {
      let errorMsg = '';
      if (error.status === 401 || error.status === 403) {
        errorMsg = 'You are not authorized to view this page'
      }
      else if (error.status === 404) {
        errorMsg = 'Data not found'
      }
      else {
        errorMsg = 'An error has occurred, please try again or contact us for support'
      }

      this.show(errorMsg, 'Error!!', 1)
    }
    return;
  }

  // Push new notifications to array with content and options
  // notificationType values  1 - Error, 2 - Success, 3 - Info/Standard
  show(textOrTpl: string | TemplateRef<any>, headerText: string, notificationType: number = 1, autoHide: boolean = true) {
    let className = notificationType === 1 ? 'bg-danger text-light' : (notificationType === 2 ? 'bg-success text-light' : 'bg-info text-light');
    let options = {
      classname: className,
      autohide: autoHide,
      headertext: headerText
    }
    this.notifications.push({ textOrTpl, ...options });
  }

  // Callback method to remove notifications DOM element from view
  remove(notification) {
    this.notifications = this.notifications.filter(x => x !== notification);
  }
}

import { PortfolioSeries } from "./portfolio-series.class";
import { Widget } from "./widget.class";
import { BaseEntityDetails } from "./entity-details.class";
import { InvestmentTypeHierarchy } from "./investment-type-hierarchy.class";
import { KeyValuePair } from "./key-value-pair.class";

export class PortfolioSeriesDashboard {
    summary: PortfolioSeries;
    widgets: Record<Widget, KeyValuePair<string|number, number>[]>;
    displayPortfolioDetail: BaseEntityDetails;
    investmentTypes: InvestmentTypeHierarchy[];
}
import { getCurrencySymbol } from "@angular/common";
import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

    transform(
        code: string,
        addParenthesis: boolean = false,
        format: 'wide' | 'narrow' = 'narrow',
        locale?: string
    ): any {
        let currencySymbol = getCurrencySymbol(code, format, locale);
        if(addParenthesis && currencySymbol){currencySymbol = `(${currencySymbol})`}
        return currencySymbol;
    }
}
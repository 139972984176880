import { Component, Input } from '@angular/core';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';

@Component({
  selector: 'app-geography-widget',
  templateUrl: './geography-widget.component.html',
  styleUrls: ['./geography-widget.component.scss']
})
export class GeographyWidgetComponent {
  @Input() widgets: Record<string, KeyValuePair<string | number, number>[]>;
  @Input() currency: string;
  byText = "By Remaining Market Value"
}

import { Injectable } from '@angular/core';
import { Holding } from '../models/classes/holding.class';

@Injectable({
  providedIn: 'root'
})
export class TableLogicService {

  constructor() { }

  sortDataForSeriesTable(investementTypeData) {
    //sort by investment type name in priority
    investementTypeData.forEach(invType => {
      switch (invType.name) {
        case "Primary Fund": {
          invType['priority'] = 1;
          break;
        }
        case "Co-investment/Direct": {
          invType['priority'] = 2;
          break;
        }
        case "Secondary": {
          invType['priority'] = 3;
          break;
        }
        case "Joint Ventures": {
          invType['priority'] = 4;
          break;
        }
        case "Seeds": {
          invType['priority'] = 5;
          break;
        }
        case "Working Capital Line": {
          invType['priority'] = 6;
          break;
        }
        default: {
          invType['priority'] = 7;
          break;
        }
      }
    })
    var sortedByPriority = investementTypeData.sort((a,b) => a.priority - b.priority)
    // if a priority type is missing (e.g., priorities are [2, 3] or [1,3,4]) update priorities to be numerical starting from 1)
    let priority = 1;
    sortedByPriority.forEach(invType => {
      invType['priority'] = priority;
      priority += 1;
    })
    return sortedByPriority;
  }

  checkDealToHoldingMapping(holdings: Holding[]) {
    if (holdings.length !== 0) {
      return holdings.length === 1 ? false : true;
    }
    else {
      return false;
    }
  }

  checkHoldingDrillThrough(holding) {
    return holding.purpose === 'FX Hedge';
  }
}

import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { EnvironmentVariablesService } from '../services/environment-variables.service';
import { UserService } from '../services/user.service';
import { ContentfulService } from '../services/contentful.service';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss']
})
export class AuthErrorComponent implements OnInit {
  clientDocsUrl: string = 'https://docs.clientscope.com';
  hideDocsButton: boolean = false;
  emailAddressText: any;
  mailToEmailAddress: any;

  constructor(
    private loading: LoadingService,
    public evService: EnvironmentVariablesService,
    private userService: UserService,
    private ContentfulService: ContentfulService
  ) {}

  ngOnInit(): void {
    this.loading.setLoading(false);
    this.checkUserDocsAccess();
    this.getContentfulData();
  }

  goToDocs() {
    window.location.href = this.clientDocsUrl;
  }

  getContentfulData():void
  {
    this.ContentfulService.getPageNotFound(this.evService.contentful['previewFlag']).subscribe((result) => {
      this.emailAddressText = result?.data?.page404Error.emailAddressText;
      this.mailToEmailAddress = result?.data?.page404Error.mailToEmailAddress;
    });
  }

  checkUserDocsAccess() {
    if (this.userService.getApplicationAccess().indexOf('ClientDocument') > -1) {
      // check if user has access to client docs, if yes then show client docs as client docs url
      this.clientDocsUrl = this.evService.clientDocs;
    } else if (this.userService.getApplicationAccess().indexOf('VISTAONE') > -1) {
      // check if user has access to vista one, if yes then show vista one as client docs url
      this.clientDocsUrl = 'https://docs.gcmlp.com/Grosvenor/Release10/me.get?dps.views.show&TOOLS'
    } else {
      this.hideDocsButton = true;
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-map-widget',
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.scss']
})
export class MapWidgetComponent {
  @Input() position: { lat: any; lng: any; };
  constructor() {}
}

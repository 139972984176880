import { Component, Input, SimpleChanges, QueryList, ViewChildren } from '@angular/core';
import { ExportCsvService } from 'src/app/services/export-csv.service';
import { CsvModes } from 'src/app/core/csv-modes.class';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbDataService } from 'src/app/services/breadcrumb-data.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { DealHierarchy } from 'src/app/models/classes/deal-hierarchy.class';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TableLogicService } from 'src/app/services/table-logic.service';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';

@Component({
  selector: 'app-series-qroll-table',
  templateUrl: './series-qroll-table.component.html',
  styleUrls: ['./series-qroll-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class SeriesQRollTableComponent {
  @Input() iconSet;
  @Input() investmentTypes;
  @Input() portfolioSummary = [];
  @Input() summary = [];
  @Input() portfolioName: string = '';
  @Input() seriesName: string = '';
  @Input() asOfDate: string = '';
  QRollData = [];
  sortingInitialized: boolean = false;
  displayedColumns: string[] = ['name', 'realizationType', 'priorNAV', 'qoQContributions', 'qoQDistributions', 'qoQGainLoss', 'nav', 'qoQTvpiChange', 'qoQIrrChange'];
  displayedHeaders: string[] = ['nameHeader', 'priorNAVHeader', 'qoQContributionsHeader', 'qoQDistributionsHeader', 'qoQGainLossHeader', 'navHeader', 'qoQTvpiChangeHeader', 'qoQIrrChangeHeader'];
  innerDisplayedColumns: string[] = ['holdingName', 'realizationType', 'holdingPriorNAV', 'holdingQoQContributions', 'holdingQoQDistributions', 'holdingQoQGainLoss', 'holdingNav', 'holdingQoQTvpiChange', 'holdingQoQIrrChange'];
  @ViewChildren(MatSort) sorts = new QueryList<MatSort>;
  dataSources: MatTableDataSource<DealHierarchy>[] = []
  expandedDeal: MatTableDataSource<DealHierarchy>[] = [];
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<DealHierarchy>>;

  constructor(
    private tableLogicService: TableLogicService, 
    private breadcrumbDataService: BreadcrumbDataService, 
    private exportCsvService: ExportCsvService, 
    public activatedRoute: ActivatedRoute,
    public activityTracking: ActivityTrackingService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.investmentTypes && this.investmentTypes) {
      this.QRollData = [...this.investmentTypes];
      this.formatQRollTableValues();
      this.sortingInitialized = false;
      this.sorts = new QueryList<MatSort>;
    }
  }

  ngDoCheck() {
    this.initializeSorting();
  }

  initializeSorting() {
    if (!this.sortingInitialized) {
      if (this.sorts.length > 0) {
        this.dataSources.forEach((x, index) => {
          x.sort = this.sorts.find((_, i) => i == index)
        });
        this.sortingInitialized = true;
      }
    }
  }

  formatQRollTableValues() {
    this.QRollData = this.tableLogicService.sortDataForSeriesTable(this.QRollData);
    this.QRollData.forEach((investmentType, i) => {
      investmentType['isExpanded'] = true;
      this.dataSources[investmentType['priority'] - 1] = new MatTableDataSource(investmentType.deals);
      investmentType.deals.forEach(deal => {
        deal['showHoldings'] = this.tableLogicService.checkDealToHoldingMapping(deal.holdings);
        if (deal.showHoldings) {
          this.expandedDeal.push(deal)
          deal['isExpanded'] = true;
        }
        deal.holdings.forEach(holding => {
          if (deal.showHoldings) {
            holding['disableDrillThrough'] = this.tableLogicService.checkHoldingDrillThrough(holding);
          } else {
            deal['disableDrillThrough'] = this.tableLogicService.checkHoldingDrillThrough(holding);
          }
        })
      })
    });
  }

  redirectToHoldingsPage(holding) {
    const params = this.activatedRoute.snapshot.params;
    return this.breadcrumbDataService.redirectToHoldingsPage(holding, params);
  }

  exportQRollDataToCsv(event: any) {
    this.activityTracking.exportToExcelClick('series qroll table', this.seriesName);
    event.target.disabled = true;
    let qrollForwardArray = [], invTypeArray = [];
    const filename = this.exportCsvService.getExportFilename(CsvModes.quarterlyRollForward, this.asOfDate, this.portfolioName, this.seriesName);
    const headerForQRollForward = this.exportCsvService.getCsvHeader(CsvModes.quarterlyRollForward);
    const headerForInvestmentType = this.exportCsvService.getCsvHeader(CsvModes.qRollForwardInvestmentType);
    qrollForwardArray.push(headerForQRollForward);
    invTypeArray.push(headerForInvestmentType);
    this.investmentTypes.forEach(invType => {
      let invTypeData = {};
      invTypeData['invType'] = invType.name;
      invTypeData['invTypePriorNav'] = invType.priorNAV;
      invTypeData['invTypeQoqContributions'] = invType.qoQContributions;
      invTypeData['invTypeQoqDistributions'] = invType.qoQDistributions;
      invTypeData['invTypeGainLoss'] = invType.qoQGainLoss;
      invTypeData['invTypeCurrentNav'] = invType.nav;
      invTypeData['invTypeQoqTvpiChange'] = invType.qoQTvpiChange;
      invTypeData['invTypeQoqIrrChange'] = invType.qoQIrrChange;
      invTypeArray.push(invTypeData);

      const sortedDeals = this.exportCsvService.sortArrayAscending(invType.deals);
      sortedDeals.forEach(deal => {
        const sortedHoldings = this.exportCsvService.sortArrayAscending(deal.holdings);
        sortedHoldings.forEach(holding => {
          let data = {};
          data['invType'] = invType.name;
          data['dealName'] = deal.name;
          data['holdingName'] = holding.name;
          data['invStatus'] = deal.realizationType;
          data['holdingPriorNav'] = holding.priorNAV;
          data['holdingQoqContributions'] = holding.qoQContributions;
          data['holdingQoqDistributions'] = holding.qoQDistributions;
          data['holdingGainLoss'] = holding.qoQGainLoss;
          data['holdingCurrentNav'] = holding.nav;
          data['holdingQoqTvpiChange'] = holding.qoQTvpiChange;
          data['holdingQoqIrrChange'] = holding.qoQIrrChange;
          qrollForwardArray.push(data);
        })
      })
    })
    const worksheets = [{name: 'Quarterly Roll Forward Table', csvArray: qrollForwardArray}, {name: 'Investment Type Subtotals', csvArray: invTypeArray}]
    this.exportCsvService.exportToExcelFile(filename, worksheets);
    event.target.disabled = false;
  }

  scrollQRTableHandler(event, investment) {
    if (event.target.scrollLeft > 0) {
      investment['displayShadowOnQRTable'] = true;
    } else {
      investment['displayShadowOnQRTable'] = false;
    }
  }

  expandTable(investment) {
    investment.isExpanded = !investment.isExpanded;
    if (investment.isExpanded) {
      this.sortingInitialized = false;
      this.sorts = new QueryList<MatSort>;
      this.initializeSorting();
    }
    this.activityTracking.expandOrCollapseToggled('investment', investment, 'series qroll table', investment.isExpanded)
  }
}
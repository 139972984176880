<link href="https://global.oktacdn.com/okta-signin-widget/5.5.2/css/okta-sign-in.min.css" type="text/css"
  rel="stylesheet" />
<div class="vh-100">
  <div class="login-wrap lg:grid lg:grid-cols-[45%_55%]">
    <div class="hidden lg:flex login-wrap__left justify-content-center align-items-center h-100"
      style="background: url('{{backgroundImageUrl}}') no-repeat center;">
      <div class="login-wrap__logo">
        <img src="{{siteLogo}}" title="GCM">
      </div>
      <div class="login-wrap__text">
        <div class="heading">{{pageHeading}}</div>
        <div [innerHTML]="pageContent | safeHtml"></div>
      </div>
      <div class="login-wrap__copyright">
        &copy; {{copyrightText}}
      </div>
    </div>
    <div class="login-wrap__right h-100 lg:my-0 lg:mx-auto lg:w-[450px]">
      <div class="flex lg:hidden gcm-logo">
        <img src="{{gcmLogo}}" title="GCM" alt="Gcm-logo" />
      </div>
      <div class="login-wrap__right__container h-100 lg:w-[450px]">
        <div class="lg:m-0 lg:pl-[32px] login-wrap__right__logo">
          <img src="{{clientScopeLogo}}" title="Client Scope" class="lg:h-[100px]" />
        </div>
        <div id="okta-login-container"></div>
      </div>
      <div class="login-wrap__terms flex lg:block ">
        <a href="javaScript:;" (click)="open(content)" data-cy="privacyButton">{{privacyPolicyLinkText}}</a>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="policy-modal" id="clientScopeApp" data-cy="privacyModal">
      <div class="modal-header border-0 pt-[1rem] px-[1.5rem] pb-0 lg:pt-[1.5rem] lg:px-[3rem]">
        <h5 class="modal-title" id="exampleModalLongTitle">{{privacyPolicyLinkText}}</h5>
        <button type="button" class="policy-modal__close close" (click)="d()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="policy-modal__body pt-[1rem] px-[1.5rem] pb-0 lg:pt-[3rem] lg:px-[3rem] lg:pb-[1.5rem]" [innerHTML]="privacyPolicyModelContents"> </div>
      <div class="modal-footer border-0">
        <button type="button" class="policy-modal__btn btn btn-primary" (click)="c()" data-cy="closeButton">{{privacyPolicyModelOnButtonText}}</button>
      </div>
    </div>
  </ng-template>
</div>
import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'currencyFormat'
})
@Injectable({
    providedIn: 'root'
})

export class CurrencyFormatPipe implements PipeTransform {
    constructor() { }
    transform(number: any, currency: string = 'USD', displayAmount?: string, hideSuffix?: boolean, hideCurrency?: boolean, fractionDigits?: number, formatZero?: boolean): any {
        if ((number || number === 0) && !isNaN(number)) {
            if (number == 0) {
                return formatZero ? this.formatNumber(0, currency, 0, hideCurrency) : '-';
            } else {
                let isNegativeValue: boolean = number < 0;
                number = Math.abs(number);
                let formattedValue = '';
                if (displayAmount === 'Billions') {
                    // billions
                    if (number >= 1000000000) {
                        formattedValue = this.formatNumber(number / 1000000000, currency, fractionDigits, hideCurrency) + (hideSuffix ? '' : 'B');
                    }
                    // millions
                    else {
                        formattedValue = this.formatNumber(number / 1000000, currency, fractionDigits, hideCurrency) + (hideSuffix ? '' : 'M');
                    }
                } else if (displayAmount === 'Millions') {
                    //show value in millions without any formatting with K, M, or B
                    formattedValue = this.formatNumber(number / 1000000, currency, fractionDigits, hideCurrency, true) + (hideSuffix ? '' : 'M');
                } else if (displayAmount === 'Thousands') {
                    //show value in thousands without any formatting with K,M, or B
                    formattedValue = this.formatNumber(number / 1000, currency, fractionDigits, hideCurrency, true)  + (hideSuffix ? '' : 'K');
                } else if (number < 1000) {
                    // if number is less than 1000 then display in thousand (K) format
                    formattedValue = this.formatNumber(number / 1000, currency, fractionDigits, hideCurrency, true)  + ('K');
                } else {
                    formattedValue = this.formatNumber(number, currency, fractionDigits);
                }
                if (isNegativeValue) {
                    return '(' + formattedValue + ')';
                } else if (number >= 0.5) {
                    return formattedValue;
                } else if (fractionDigits === 2) {
                    return this.formatNumber(0, currency, 2, hideCurrency);
                } else {
                    return this.formatNumber(0, currency, 1, hideCurrency);
                }
            }
        }
        else {
            return 'N/A';
        }
    }

    private formatNumber(value: number, currency: string = 'USD', fractionDigits: number = 1, hideCurrency: boolean = false, nonFormattedAmount: boolean = false,) {
        let locale = navigator.language || 'en';
        return Intl.NumberFormat(locale, { notation: nonFormattedAmount ? "standard" : "compact", minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits, currency: currency, signDisplay: 'never', currencySign: 'accounting', currencyDisplay: 'symbol', style: hideCurrency ? "decimal" : "currency" }).format(value);
    }
}
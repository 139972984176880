<div class="grid grid-cols-1 lg:grid-cols-2 mt-4" tourAnchor="series.dashboard.keyPerformance.widget">
    <div class="pr-4 lg:pr-7 pl-0 mln-15">
        <app-base-widget>
            <mat-tab-group animationDuration="0" (selectedTabChange)="handleChange($event)" [selectedIndex]="1"
                class="key-performance-sm">
                <mat-tab [disabled]="true" class="text-md lg:text-2xl" label="Key P&L Drivers (Quarter to Date)">
                    <ng-template matTabContent></ng-template>
                </mat-tab>
                <mat-tab label={{tabs[1]}}>
                    <ng-template matTabContent>
                        <app-base-widget [card]="false" [error]="keyPerformanceDataForQuarterTop?.error"
                            data-cy="keyPerformanceForQuarterTop">
                            <span class="by-text">By Gain/(Loss) | Expressed in {{currency}} (in thousands)</span>
                            <gcm-chart [chartData]="keyPerformanceDataForQuarterTop" chartType="bar"
                                [chartStyle]="kdpQuarterChartStyle"
                                [chartOptions]="keyPerformanceQuarterChartOptionsTop" [tabEvent]="tabEvent"></gcm-chart>
                        </app-base-widget>
                    </ng-template>
                </mat-tab>
                <mat-tab label={{tabs[2]}}>
                    <ng-template matTabContent>
                        <app-base-widget [card]="false" [error]="keyPerformanceDataForQuarterBottom?.error"
                            data-cy="keyPerformanceForQuarterBottom">
                            <span class="by-text">By Gain/(Loss) | Expressed in {{currency}} (in thousands)</span>
                            <gcm-chart [chartData]="keyPerformanceDataForQuarterBottom" chartType="bar"
                                [chartStyle]="kdpQuarterChartStyle"
                                [chartOptions]="keyPerformanceQuarterChartOptionsBottom" [tabEvent]="tabEvent">
                            </gcm-chart>
                        </app-base-widget>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </app-base-widget>
    </div>

    <div class="pr-4 pl-0 mt-4 lg:mt-0 mln-15 lg:ml-0">
        <app-base-widget>
            <mat-tab-group animationDuration="0" (selectedTabChange)="handleChange($event)" [selectedIndex]="1"
                class="key-performance-sm">
                <mat-tab [disabled]="true" label="Key P&L Drivers (Inception to Date)">
                    <ng-template matTabContent></ng-template>
                </mat-tab>
                <mat-tab label={{tabs[1]}}>
                    <ng-template matTabContent>
                        <app-base-widget [card]="false" [error]="keyPerformanceDataSinceInceptionTop?.error"
                            data-cy="keyPerformanceSinceInceptionTop">
                            <span class="by-text">By Gain/(Loss) | Expressed in {{currency}} (in thousands)</span>
                            <gcm-chart [chartData]="keyPerformanceDataSinceInceptionTop" chartType="bar"
                                [chartStyle]="kdpInceptionChartStyle"
                                [chartOptions]="keyPerformanceSinceInceptionChartOptionsTop" [tabEvent]="tabEvent">
                            </gcm-chart>
                        </app-base-widget>
                    </ng-template>
                </mat-tab>
                <mat-tab label={{tabs[2]}}>
                    <ng-template matTabContent>
                        <app-base-widget [card]="false" [error]="keyPerformanceDataSinceInceptionBottom?.error"
                            data-cy="keyPerformanceSinceInceptionBottom">
                            <span class="by-text">By Gain/(Loss) | Expressed in {{currency}} (in thousands)</span>
                            <gcm-chart [chartData]="keyPerformanceDataSinceInceptionBottom" chartType="bar"
                                [chartStyle]="kdpInceptionChartStyle"
                                [chartOptions]="keyPerformanceSinceInceptionChartOptionsBottom" [tabEvent]="tabEvent">
                            </gcm-chart>
                        </app-base-widget>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </app-base-widget>
    </div>
</div>
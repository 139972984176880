<div class="main-content mt-[65px] lg:mt-[82px]" data-cy="investorDashboard">
    <div *ngIf="iconSet" class="px-[5px] lg:pl-[9rem] pb-3 lg:pt-3">
        <div *ngIf="errorMessage || showNoAccessError" class="ml-3">
            <div *ngIf="showNoAccessError; else showErrorMessage">
                <app-no-access-error (updateInvestor)="updateInvestor($event)"></app-no-access-error>
            </div>
            <ng-template #showErrorMessage>
                <div class="row mr-0">
                    <div class="col-12">
                        <div class="mt-3 cumulative-graph">
                            <div>
                                <h3 style="text-align: center;">{{errorMessage}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-container *ngIf="!errorMessage && !showNoAccessError">
            <div class="container">
                <div class="heading-row">
                    <div class="grid grid-cols-2 heading">
                        <h1 class="heading__title text-5xl md:text-6xl">Dashboard</h1>
                        <div class="text-right asOfDate">
                            <span class="asOfDate__text">as of</span>
                            <select name="asOfDateddl" class="form-select asOfDate__dropdown" [(ngModel)]="selectedAsOfDate"
                                (change)='updateAsOfDate($event.target.value)' data-cy="asOfDateDropdown"
                                tourAnchor="investor.dashboard.reportingPeriod">
                                <option *ngFor="let date of asOfDropdownData" [value]="date">
                                    {{date | dateFormat : 'MM/dd/yyyy'}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="investorPortfolioData.length > 0">
                    <!-- Investment summary div -->
                    <ng-container *ngIf="investorSummary?.currency">
                        <app-investor-summary [iconSet]="iconSet"
                            [investorSummary]="investorSummary" [showBanner]="showBanner"></app-investor-summary>
                    </ng-container>
                    <!-- Portfolio series div -->
                    <app-portfolio-summary [investorPortfolioData]="investorPortfolioData" [iconSet]="iconSet"
                        [activeInvestorMetadata]="activeInvestorMetadata" [selectedAsOfDate]="selectedAsOfDate"
                        ></app-portfolio-summary>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
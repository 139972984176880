
export class SupportRequest {
    public constructor(supportRequest: SupportRequest) {
        Object.assign(this, supportRequest);
    }
    raygunId: string;
    subject: string;
    category: SupportRequestCategory;
    message: string;
}

export enum SupportRequestCategory {
    Unknown = 0,
    TechnicalSupport = 1,
    DataInquiry = 2
}
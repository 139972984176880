import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from './environment-variables.service';
import { SumologicService } from './sumologic.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private evService: EnvironmentVariablesService, private sumologicService: SumologicService) { }

  log(message: any, optionalData: any = {}) {
    try {
      const loggingTargets = this.evService.loggingTargets;

      if (loggingTargets.length > 0) {
        let data;
        let messageToLog = message;
        if (typeof message !== 'string' && (optionalData == null || optionalData == undefined || Object.keys(optionalData).length == 0)) {
          data = message;
          messageToLog = '';
        }
        else if (optionalData != null && optionalData != undefined && (Object.keys(optionalData).length > 0 || optionalData instanceof Error )) {
          data = optionalData;
        }
        else {
          data = '';
        }
        if (loggingTargets.indexOf('console') > -1) {
          if (optionalData instanceof Error) {
            console.error(messageToLog, data);
          }
          else {
            console.log(messageToLog, data)
          }
        }

        if (loggingTargets.indexOf('sumologic') > -1) {
          let dataToLog = Object.assign({}, optionalData);
          const obj = { message: messageToLog, data: dataToLog }
          this.sumologicService.log(obj, {});

          // Flush any logs, typically this line would be in your shutdown code
          this.sumologicService.flushLogs();
        }
      }
    }
    catch (err) {
      console.log('Error in logging service log method',err);
    }
  }
}

<div class="logout-wrap pl-0 lg:pl-[8.5rem] w-full">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="logout-wrap__content">
          <div class="pt-3 text-center">
            <h3><img
                src="https://images.ctfassets.net/hhmmh7cybzrp/6HRf7sYiEfZmUb9TuZW86D/04f1d998510aacd52023292b56fddf32/GCM-Logo-white.png"
                title="GCM-ClientExperience"></h3>
            <p class="pt-2">Hang on a moment while we log you out.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row cumulative-highchart-row mt-3 md:pr-0" tourAnchor="investor.dashboard.chart">
    <div class="card">
        <div class="card-body">
            <app-base-widget [error]="error" [card]="false" data-cy="cumulativeChartWidget">
                <div header class="widget-title">Cumulative Contributions & Distributions</div>
                <div class="chart-div">
                    <gcm-chart [chartStyle]="cumulativeChartStyle" chartType="column"
                        [chartOptions]="cumulativeChartOptions" />
                </div>
            </app-base-widget>
        </div>
    </div>
</div>
import { EntityDetails } from "../interfaces/entity-details.interface";

export class BaseEntityDetails implements EntityDetails {
    reportingPeriod: string;
    qtdGain: number | null;
    itdGain: number | null;
    commitment: number | null;
    contributions: number | null;
    distributions: number | null;
    totalValue: number | null;
    nAV: number | null;
    priorNAV: number | null;
    remainingCommitment: number | null;
    irr: number | null;
    priorIrr: number | null;
    tvpi: number | null;
    priorTvpi: number | null;
    qoQTvpiChange: number | null;
    qoQIrrChange: number | null;
    qoQContributions: number | null;
    qoQDistributions: number | null;
    qoQAdjustedNAV: number | null;
    qoQGainLoss: number | null;
    masterId: number;
    name: string;
    currency: string;
    publishValuationType: string;
}
  
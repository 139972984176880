import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../services/contentful.service';
import { EnvironmentVariablesService } from '../services/environment-variables.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {
  // ContentFul Declarations
  loginPageContents: any[];
  error: any;
  pageContent: any;
  pageHeading: any;
  siteLogo: any;
  emailAddressText: any;
  mailToEmailAddress: any;
  pageText: any;
  pageTitle: any;
  goToDashboardButtonText: any;
  errorImageUrl: any;
  gcmLogoUrl: any;
  constructor(private ContentfulService: ContentfulService, private evService: EnvironmentVariablesService) { }

  ngOnInit(): void {
    this.getContentfulData();
  }

  getContentfulData():void
  {
    this.ContentfulService.getPageNotFound(this.evService.contentful['previewFlag']).subscribe((result) => {
      this.loginPageContents = result?.data?.page404Error;
      this.error = result.error;
      this.emailAddressText = result?.data?.page404Error.emailAddressText;
      this.mailToEmailAddress = result?.data?.page404Error.mailToEmailAddress;
      this.pageText= result?.data?.page404Error.pageText;
      this.pageTitle= result?.data?.page404Error.pageTitle;
      this.goToDashboardButtonText= result?.data?.page404Error.goToDashboardButtonText;
      this.gcmLogoUrl=result?.data?.page404Error.gcmLogo.url;
      this.errorImageUrl=result?.data?.page404Error.errorImage.url;
    });
  }
}

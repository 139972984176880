import { Component, Input } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  iconSet: any;
  @Input() tooltipPosition: string = 'above';
  @Input() tooltipInfo: string;
  @Input() tooltipClass: string = '';

  constructor(
    private contentfulService: ContentfulService,
  ) { }

  ngOnInit(): void {
    this.contentfulService.getIconSetValue().subscribe(data => {
      if (data) this.iconSet = data;
    });
  }
}

import { Injectable } from '@angular/core';
import { createStore, Store, filterNil } from '@ngneat/elf';
import { withEntities, withActiveId, setActiveId, getActiveEntity, getEntity, upsertEntities, hasEntity, deleteAllEntities, setEntities, selectAllEntities, selectActiveEntity } from '@ngneat/elf-entities';
import { ApiService } from 'src/app/core/http/api.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BaseMedEntity } from 'src/app/models/classes/base-med-entity.class';

@Injectable({ providedIn: 'root' })
export abstract class GenericRepository<T extends BaseMedEntity> {

  private store: Store = null;
  abstract fetchEntityFromDataSource(id: number): Observable<T>;

  constructor(protected apiService: ApiService, private storeName: string) {
    this.store = createStore(
      { name: storeName },
      withEntities<T>(),
      withActiveId()
    )
  }

  getEntity(id: number): Observable<T> {
    return this.hasEntity(id) ? of(this.store.query(getEntity(id))) : this.fetchEntity(id)
  }

  setActiveEntity(id: number): void {
    if (!this.hasEntity(id)) {
      this.fetchEntity(id).subscribe(entity => {
        this.store.update(setActiveId(entity.id))
      })
    }
    else {
      this.store.update(setActiveId(id))
    }
  }

  clearEntities(): void {
    this.store.update(deleteAllEntities())
  }

  getAllEntities(): Observable<T[]> {
    return this.store.pipe(selectAllEntities()).pipe(filterNil());
  }

  getActiveEntity(): T { return this.store.query(getActiveEntity()); }

  selectActiveEntity(): Observable<T> { return this.store.pipe(selectActiveEntity()).pipe(filterNil())}

  private fetchEntity(id: number): Observable<T> {
    return this.fetchEntityFromDataSource(id).pipe(map(entity => {
      this.setEntity(entity); return entity;
    }));
  }

  private hasEntity(id: number): boolean { return this.store.query(hasEntity(id)) }

  private setEntity(entity: T): void { this.store.update(upsertEntities(entity)); }

  protected setAllEntities(entitites: T[]){this.store.update(setEntities(entitites))}
}
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BehaviorSubject } from 'rxjs';
import { ContentfulService } from './contentful.service';
import { EnvironmentVariablesService } from './environment-variables.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {
  sessiontimeout: boolean = false;
  private sessionPageContent: BehaviorSubject<any> = new BehaviorSubject(null);
  public sessionPageContent$ = this.sessionPageContent.asObservable();
  sessionTimeLeft: number;

  constructor(private idle: Idle, private keepalive: Keepalive, private cfService: ContentfulService, private evService: EnvironmentVariablesService, private userService: UserService, private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.userService) {
          this.sessionPageContent$.subscribe((sessionData) => {
            if (!sessionData) {
              this.cfService.fetchCommonAssets('session', '').subscribe(result => {
                this.sessionPageContent.next(JSON.stringify(result.data.sessionTimeoutSection));
              });
            }
          })
          idle.watch();
          this.sessiontimeout = false;
        }
        else {
          idle.stop();
        }
      }
    })
  }

  setIdleConfigs() {
    // how long can they be inactive before considered idle, in seconds
    this.idle.setIdle(this.evService.sessionStandAloneTime);
    this.idle.setTimeout(60); // how long can they be idle before considered timed out, in seconds
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idle.clearInterrupts();
      if (this.userService.isAuthenticated()) {
        this.sessiontimeout = true;
      }
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.sessionTimeLeft = countdown;
    });

    this.idle.onTimeout.subscribe(() => {
      this.sessiontimeout = false;
      this.userService.logoutApp();
    });

    this.keepalive.interval(15); // will ping at this interval while not idle, in seconds
  }

  resetSessionIdleData() {
    this.userService.checkOktaSessionExistsAndLogoutIfExpired();
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.sessiontimeout = false;
    this.idle.watch();
  }

  sessionContinueClick() {
    this.resetSessionIdleData();
    localStorage.setItem('session-continue', 'session continued' + Math.random());
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-header-sidenav-layout',
  templateUrl: './header-sidenav-layout.component.html'
})
export class HeaderSidenavLayoutComponent {
  showSideNav: boolean = false;

  constructor() { }

  openSideNav() {
    this.showSideNav = !this.showSideNav;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulService } from '../services/contentful.service';
import { LoggingService } from '../services/logging.service';
import { NotificationService } from '../services/notification.service';
import { SearchEngineService } from '../services/search-engine.service';
import { InvestorRepository } from '../services/repositories/investor.repository';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { ApiService } from '../core/http/api.service';
import { InvestorDashboard } from '../models/classes/investor-dashboard.class';
import { LoadingService } from '../services/loading.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { SweetAlertService } from '../services/sweet-alert.service';
import { EnvironmentVariablesService } from '../services/environment-variables.service';
import { NgTourStepsService } from '../services/ng-tour-steps.service';
import { BreadcrumbDataService } from '../services/breadcrumb-data.service';
import { InvestorMeta } from '../models/classes/investor-meta.class';
import { ActivityTrackingService } from '../services/activity-tracking.service';

@Component({
  selector: 'app-investor-dashboard',
  templateUrl: './investor-dashboard.component.html',
  styleUrls: ['./investor-dashboard.component.scss']
})
export class InvestorDashboardComponent implements OnInit, OnDestroy {
  iconSet: {};
  errorMessage: any;
  currentPage: any;
  investorSummary: any = {};
  HeadingasOfDate: string = '';
  investorPortfolioData: any = [];
  $activeInvestor: Subscription;
  reportingPeriod: string = '';
  investorId: any;
  cumulativeData: any = [];
  showNoAccessError: boolean = false;
  tourStart$: Subscription;
  activeInvestorMetadata: any;
  selectedAsOfDate: string = '';
  asOfDropdownData: string[] = [];
  showBanner: boolean = false;

  constructor(
    private ContentfulService: ContentfulService,
    private seoService: SearchEngineService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private loggingService: LoggingService,
    private notificationService: NotificationService,
    private investorRepository: InvestorRepository,
    private apiService: ApiService,
    private loadingService: LoadingService,
    private ngTourSteps: NgTourStepsService,
    private tourService: TourService,
    private swalService: SweetAlertService,
    private evService: EnvironmentVariablesService,
    private breadcrumbDataService: BreadcrumbDataService,
    private activityTracking: ActivityTrackingService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.showNoAccessError = false;
    this.seoService.setCanonicalURL();
    //// Get contentFul common Lables ////
    this.currentPage = this.router.url.substring(1).split('/')[0];
    this.getQueryParams();
    this.getContentfulData();
    this.subscribeToInvestorData();
    this.initializeUiTourForInvestorDashboard();
  }

  getQueryParams() {
    const queryParams = this.activeRoute.snapshot.queryParams;
    this.reportingPeriod = queryParams['reportingdate']
    this.investorId = queryParams['investorid'];
    this.getInvestorIds(this.investorId);
  }

  subscribeToInvestorData() {
    this.$activeInvestor = this.investorRepository.selectActiveEntity().subscribe((res: InvestorMeta) => {
      this.activeInvestorMetadata = res;
      this.getAsOfDates();
      if (res.id) {
        this.router.navigate(
          [], {
          relativeTo: this.activeRoute,
          queryParams: { investorid: res.id },
          queryParamsHandling: 'merge'
        });
        this.refreshActiveInvestorData({id: res.id});
      }
    })
  }

  getAsOfDates() {
    this.getQueryParams();
    this.asOfDropdownData = [];
    let asOfDateData = [];
    asOfDateData = this.activeInvestorMetadata.reportingPeriods 
    let hidePriorToDate = new Date(this.evService.hideDatesPriorToDate)
    asOfDateData = asOfDateData.filter(d => new Date(d).getTime() >= hidePriorToDate.getTime()) //Remove dates prior to cutoff
    this.asOfDropdownData = asOfDateData.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    if (this.reportingPeriod && this.asOfDropdownData.find(date => date === this.reportingPeriod)) {
      this.selectedAsOfDate = this.reportingPeriod;
    } else {
      this.selectedAsOfDate = this.asOfDropdownData[0];
    }
  }

  getContentfulData(): void {
    this.ContentfulService.getIconSetValue().subscribe(data => {
      if (data) this.iconSet = data;
    });
  }

  getInvestorIds(queryParamsinvestorid: any) {
    const assignedInvestorIds = this.userService.getInvestorIds();
    if (this.userService.isExternalUser()) {
      if (queryParamsinvestorid && !assignedInvestorIds.includes(parseInt(queryParamsinvestorid))) {
        let parametersErrorMessage = "Do not have Access to the Investor " + queryParamsinvestorid;
        let error = "On " + this.currentPage.toUpperCase() + " - " + parametersErrorMessage;
        this.showNoAccessError = true;
        this.loggingService.log(this.userService.getUserEmail() + ' - ' + error);
        this.loadingService.setLoading(false);
        return;
      }
    }
  }

  refreshActiveInvestorData(requestObj: {id, requestedReportingPeriod?}): void {
    this.showNoAccessError = false;
    this.errorMessage = '';
    this.investorPortfolioData = [];
    this.loadingService.setLoading(true);
    const reportingPeriod = requestObj.requestedReportingPeriod ?? this.breadcrumbDataService.getReportingPeriodForSelectedInvestor(requestObj.id);
    this.fetchDashboardDataFromApi(requestObj.id, reportingPeriod).then((data: InvestorDashboard) => {
      this.investorSummary = data.summary;
      this.router.navigate(
        [], {
        relativeTo: this.activeRoute,
        queryParams: { reportingdate: reportingPeriod },
        queryParamsHandling: 'merge'
      });
      let portfolios = this.objectToNestedArrayList(data?.portfolioBalanceSummaries);
      let portfolioSeries = this.objectToNestedArrayList(data?.portfolioSeriesBalanceSummaries);
      this.cumulativeData = [];
      this.cumulativeData = this.nestedArrayListToFlatArrayList(portfolios);
      this.investorPortfolioData = data?.portfolios;
      this.investorPortfolioData.forEach(portfolioData => {
        portfolioData.portfolioBalanceSummary = this.nestedArrayListToFlatArrayList(portfolios.filter(p => Number(p[0]) === portfolioData.masterId));
        portfolioData.portfolioSeries.forEach(series => {
          series.portfolioSeriesBalanceSummary = this.nestedArrayListToFlatArrayList(portfolioSeries.filter(ps => Number(ps[0]) === series.masterId));
        })
      })
      this.showBanner = this.investorPortfolioData.filter(x => x.reportingPeriod.slice(0,10) != this.selectedAsOfDate).length > 0;
      this.loadingService.setLoading(false);
    }, err => {
      this.loggingService.log("----- Dashboard investor API Exception -----", err);
      this.notificationService.showErrorNotification(err);
      this.loadingService.setLoading(false);
    })
  }

  ngOnDestroy(): void {
    this.$activeInvestor.unsubscribe();
    this.tourStart$.unsubscribe();
  }

  fetchDashboardDataFromApi(investorId, reportingDate) {
    return new Promise((resolve, reject) => {
      this.apiService.getInvestorPortfolioDashboardDetails(Number(investorId), reportingDate).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          this.errorMessage = err?.error?.errors[0]?.message ?? 'Something went wrong, Please try again!';
          reject(err);
        }
      });
    })
  }

  updateInvestor(id: number) {
    this.refreshActiveInvestorData({id})
  }

  initializeUiTourForInvestorDashboard() {
    this.ngTourSteps.getContentfulDataFetchedValue().subscribe((data: boolean) => {
      if (data) this.tourService.initialize(this.ngTourSteps.investorDashboardSteps);
    })
    this.tourStart$ = this.tourService.start$.subscribe(() => {
      this.tourService.end$.subscribe(() => {
        this.swalService.modalAlert('Tour Completed', `Click <img src="./../${this.evService.assetsFolderPath}/images/tour.png"> at anytime if you wish to return to tour`, null);
        this.tourService.end$.complete();
        this.tourService.start$.complete();
        if (!this.userService.getTutorialComplete()) {
          // if tutorial is being completed for the first time, then update tutorialCompleted claim to be true
          this.apiService.updateTutorialComplete().subscribe({
            next: () => {
              //refresh okta token to show updated tutorialCompeleted claim to true
              this.userService.refreshOktaIdToken();
            },
            error: () => {
              console.log("Error updating tutorial complete claim")
            }
          });
        }
      })
    })

    this.tourService.stepHide$.subscribe(data => {
      if (data.direction === 0 && data.step.anchorId === "investor.dashboard.portfolio") {
        (document.querySelectorAll('[data-cy="expandIcon00"]')[0] as HTMLElement)?.click();
      }
    })
  }

  objectToNestedArrayList(obj: any): any[] {
    let arr = Object.keys(obj).map((key) => [key, obj[key]]);
    arr.forEach(item => {
      item[1] = Object.keys(item[1]).map((key) => [key, item[1][key]]);
    })
    return arr;
  }

  nestedArrayListToFlatArrayList(array: any[]) {
    return array.map(x => x[1]).flatMap(x => x).map((x) => { return Object.assign(x[1], { reportingPeriod: x[0] }) });
  }


  updateAsOfDate(date) {
    this.activityTracking.toggledAsOfDate(date)
    this.loadingService.setLoading(true);
    if (date) {
      let reportingDate = date.slice(0, 10);
      this.reportingPeriod = reportingDate;
      this.router.navigate(
        [], {
        relativeTo: this.activeRoute,
        queryParams: { reportingdate: reportingDate },
        queryParamsHandling: 'merge'
      });
      this.investorId = this.activeRoute.snapshot.queryParams['investorid'];
      this.refreshActiveInvestorData({id: this.investorId, requestedReportingPeriod: reportingDate})
    }
    this.loadingService.setLoading(false);
  }
}

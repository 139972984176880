import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-expanded-view-slider',
  templateUrl: './expanded-view-slider.component.html',
  styleUrls: ['./expanded-view-slider.component.scss']
})
export class ExpandedViewSliderComponent {
  @Input() expandView = false;
  @Output() updateTableColumns: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  updateColumns(expandView) {
    this.updateTableColumns.emit(expandView)
  }
}

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalContentComponent } from '../shared/ngb-modal-content/ngb-modal-content.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private ngbModal: NgbModal) { }

  showDefaultModalComponent(headerText: any, bodyText: any, closeText?: any) {
    const modalRef = this.ngbModal.open(NgbModalContentComponent);
    modalRef.componentInstance.bodyText = bodyText;
    modalRef.componentInstance.headerText = headerText;
    modalRef.componentInstance.closeText = closeText || "Close";
    return modalRef;
  }
}
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Highcharts from 'highcharts';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { merge } from 'lodash';
import MapModule from 'highcharts/modules/map';
import FunnelModule from 'highcharts/modules/funnel'
import { DataSeries } from 'src/app/models/classes/data-series.class';
MapModule(Highcharts);
FunnelModule(Highcharts);

@Component({
  selector: 'gcm-chart',
  templateUrl: './gcm-chart.component.html'
})
export class GcmChartComponent implements OnChanges, OnInit {
  @Input() chartType: 'bar'|'line'|'map' = null;
  @Input() chartOptions: Highcharts.Options = null;
  @Input() chartData: DataSeries|DataSeries[] = null;
  @Input() updateFlag: boolean = false;
  @Input() chartStyle: Record<string, string> = {};
  @Input() mapChartData: Record<string, number>[] = [];
  @Input() chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  @Input() noMerge: boolean = false;
  @Input() set reflowEvent(event){
    this.reflow()
  }
  // Highcharts Config
  Highcharts: typeof Highcharts = Highcharts; // required
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  mergedOptions: Highcharts.Options = null;
  chart: Highcharts.Chart = null;

  constructor(
    private highChartsService: HighchartsService
  ) { }
  ngOnInit(): void {
    const gcmChart = this;
    this.setChart = function (newChart) {
      gcmChart.chart = newChart;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartType || changes.chartData || changes.chartOptions || changes.mapChartData) {
      if (this.chartType === 'map' || this.noMerge) {
        this.mergedOptions = this.chartOptions;
      }
      else {
        // Combine chart-specific config with the global default Highcharts config
        this.mergedOptions = merge(this.highChartsService.getDefaultHighchartsOptions(this.chartType, this.chartData), this.chartOptions);
      }
      this.chart?.reflow();
    }
  }

  ngAfterViewInit(): void {
  }

  reflow(): void {
      if(this.chart){
        this.chart.reflow();
      }
  }
  setChart: Function = null;
}
export class AssetSummaryContentful {
    showAssetUrl: boolean;
    summary: string;
    title: string;
    logo: string;
    slidingImgs: any[] = [];
    assetURL: string;
    highlights: boolean;
    highlightsContents: any;
    showMap: boolean = false;
    position: { lat: any; lng: any; };
}
import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    ScreenSizeType,
    BreakpointType,
    DeviceType,
    OrientationType,
} from './../models/enums/breakpoint.enum';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root',
})

//This file code has been taken from below mentioned links
// https://github.com/8Tesla8/angular-responsive-design/tree/main/src/app/responsive
// https://medium.com/geekculture/how-to-make-responsive-design-in-angular-1ad9d936dc16

export class BreakpointService {
    constructor(breakpointObserver: BreakpointObserver) {
        this.checkScreenSize(breakpointObserver);
        this.checkDeviceTypeAndOrientation(breakpointObserver);
    }

    private _screenSize: ReplaySubject<ScreenSizeType> = new ReplaySubject(null);
    private _deviceType: ReplaySubject<DeviceType> = new ReplaySubject(null);
    private _orientation: ReplaySubject<OrientationType> = new ReplaySubject(null);

    getScreenSize(): Observable<ScreenSizeType> {
        return this._screenSize.asObservable();
    }

    getDeviceType(): Observable<DeviceType> {
        return this._deviceType.asObservable();
    }

    getOrientation(): Observable<OrientationType> {
        return this._orientation.asObservable();
    }

    private readonly screenSizeBreakpoints = new Map([
        [Breakpoints.XSmall, ScreenSizeType.XSmall],
        [Breakpoints.Small, ScreenSizeType.Small],
        [Breakpoints.Medium, ScreenSizeType.Medium],
        [Breakpoints.Large, ScreenSizeType.Large],
        [Breakpoints.XLarge, ScreenSizeType.XLarge],
    ]);

    private checkScreenSize(breakpointObserver: BreakpointObserver): void {
        breakpointObserver
            .observe([
                Breakpoints.XSmall,
                Breakpoints.Small,
                Breakpoints.Medium,
                Breakpoints.Large,
                Breakpoints.XLarge,
            ])
            .subscribe((result: any) => {
                for (const query of Object.keys(result.breakpoints)) {
                    if (result.breakpoints[query]) {
                        const screenSize =
                            this.screenSizeBreakpoints.get(query) ??
                            ScreenSizeType.Unknown;
                        this._screenSize.next(screenSize);
                    }
                }
            });
    }

    private readonly deviceAndOrientation = new Map([
        [Breakpoints.HandsetLandscape, BreakpointType.HandsetLandscape],
        [Breakpoints.HandsetPortrait, BreakpointType.HandsetPortrait],
        [Breakpoints.TabletLandscape, BreakpointType.TabletLandscape],
        [Breakpoints.TabletPortrait, BreakpointType.TabletPortrait],
        [Breakpoints.WebLandscape, BreakpointType.WebLandscape],
        [Breakpoints.WebPortrait, BreakpointType.WebPortrait],
    ]);

    private checkDeviceTypeAndOrientation(breakpointObserver: BreakpointObserver): void {
        breakpointObserver
            .observe([
                Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait,
                Breakpoints.WebLandscape, Breakpoints.WebPortrait,
                Breakpoints.TabletLandscape, Breakpoints.TabletPortrait,
            ])
            .subscribe((result: any) => {
                let orientationTypes = Object.keys(OrientationType).map((key) => key);

                let deviceTypes = Object.keys(DeviceType).map((key) => key);

                for (const query of Object.keys(result.breakpoints)) {
                    if (result.breakpoints[query]) {
                        let type = this.deviceAndOrientation.get(query) ?? BreakpointType.Unknown;

                        orientationTypes.forEach((element) => {
                            if (type.indexOf(element) !== -1)
                                this._orientation.next(element as OrientationType);
                        });

                        deviceTypes.forEach((element) => {
                            if (type.indexOf(element) !== -1)
                                this._deviceType.next(element as DeviceType);
                        });
                    }
                }
            });
    }
}
import { Component, Input, SimpleChanges } from '@angular/core';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { ConstantQueryService } from 'src/app/services/constant-query.service';
import { TvpiIrrFormatService } from 'src/app/services/tvpi-irr-format.service';

@Component({
  selector: 'app-financial-summary',
  templateUrl: './financial-summary.component.html',
  styleUrls: ['./financial-summary.component.scss']
})
export class FinancialSummaryComponent {
  @Input() iconSet: KeyValuePair<string, string>;
  @Input() assetType: string;
  @Input() selectedAsOfDate: string;
  @Input() financialData: Record<string, string | number>;
  showWidget: boolean = true;

  constructor(public tvpiIrrFormat: TvpiIrrFormatService,
    private constants: ConstantQueryService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.financialData && this.financialData) {
      let filteredFinancialData = Object.keys(this.financialData)
      .filter(key => this.constants.financialValues.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.financialData[key];
        return obj;
      }, {});
      this.showWidget = Object.values(filteredFinancialData).filter(val => val !== undefined && val !== null).length > 0;
    }
  }
}

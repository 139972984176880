<div class="terms-container" *ngIf="pageLabels">
  <div class="terms-container__header flex flex-wrap p-10 md:py-20 md:pl-20 md:pr-10">
    <div class="logo lg:mr-auto">
      <img src="{{LogoImageUrl}}" title="GCM">
    </div>
    <div class="terms-container__header__link mt-5 lg:mt-0 text-smd md:text-lg">
      &copy; {{copyrightText}}
      <a href="mailto:{{mailToEmailAddress}}" class="ml-0 md:ml-5">{{emailAddressText}}</a>
    </div>
  </div>
  <div class="container">
    <div class="terms-container__wrapper px-8 py-7 md:px-24 md:py-14 text-justify">
      <div class="terms-heading text-3xl md:text-7xl mb-7 md:mb-14">{{pageHeading}}</div>
      <div class="text-sm md:text-md" [innerHTML]="pageContent | safeHtml"></div>

      <div class="pt-4 flex justify-end">
        <div class="pr-2">
          <button class="terms-container__wrapper__btn btn btn-outline text-sm md:text-md"
            (click)="decline();">{{declineButtonText}}</button>
        </div>
        <div>
          <button class="terms-container__wrapper__btn btn btn-primary text-sm md:text-md"
            (click)="acceptTnC();">{{acceptButtonText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
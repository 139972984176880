import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'assetClass'
})

export class AssetClassPipe implements PipeTransform {
    constructor() { }

    transform(assetClass: string): any {
        if (assetClass === 'Real Estate') {
            return 'icon.realEstate';
        }
        else if (assetClass === 'Private Equity') {
            return 'icon.privateEquity';
        }
        else if (assetClass === 'Infrastructure') {
            return 'icon.infra';
        }
        else if (assetClass === 'Digital Asset') {
            return 'icon.digitalAsset';
        }
        else if (assetClass === 'Private Credit') {
            return 'icon.privateCredit';
        }
        else if (assetClass === 'Absolute Returns Strategy') {
            return 'icon.ARS';
        }
    }
}
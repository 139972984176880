import { Component, OnInit } from '@angular/core';
import { Okta } from '../shared/okta.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  oktaSignIn;
  user;

  constructor(
    private okta: Okta,
    private userService: UserService
  ) {
    this.oktaSignIn = this.okta.getWidget();
  }

  async ngOnInit() {
    this.userService.logoutApp()
  }

}

<div tourAnchor="series.dashboard.summary">
    <div class="portfolio-summary">
        <div class="portfolio-title pb-0 text-md lg:text-lg" data-cy="portfolioLabel"> {{portfolioSummary[0]?.name}} </div>
    </div>
    <div class="series-summary">
        <div class="series-title pt-0">
            <div class="series-name text-md lg:text-2xl" data-cy="seriesLabel">{{seriesSummary[0]?.name}}</div>
            <div class="date-text p-1.5 ml-0 lg:pt-3 flex" data-cy="asOfDateLabel">as of {{selectedDateObj | dateFormat : 'MM/dd/yyyy'}}
                <div *ngIf="portfolioSummary[0]?.publishValuationType === 'Audit'" class="final" data-cy="auditPublished">-- Final</div>
            </div>
        </div>
        <div>
            <img [src]="assetClassImage[1]" class="asset-image" [matTooltip]="assetClassImage[0]" matTooltipClass="blue-tooltip" matTooltipPosition="right">
        </div>
        <div class="series-card bg-white grid gap-4 grid-cols-2 md:grid-cols-6  text-sm lg:text-lg" data-cy="seriesCard"
            *ngIf="!noResultsSeries">
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">{{seriesSummary[0]?.commitment |
                    currencyFormat:portfolioSummary[0]?.currency}}</h2>
                <p class="card-label">Commitments</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">{{seriesSummary[0]?.contributions |
                    currencyFormat:portfolioSummary[0]?.currency}}
                </h2>
                <p class="card-label">Contributions</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">{{seriesSummary[0]?.distributions |
                    currencyFormat:portfolioSummary[0]?.currency}}
                </h2>
                <p class="card-label">Distributions</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">
                    {{seriesSummary[0]?.nav |
                        currencyFormat:portfolioSummary[0]?.currency}}
                </h2>
                <p class="card-label">Net Asset Value</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start ml-16 md:ml-5">
                <h2 class="card-value d-flex" data-cy="tvpi" [ngClass]="{'no-icon': !seriesSummary[0]?.tvpiIcon}">
                    {{seriesSummary[0]?.tvpi | tvpiValueFormat}}
                    <div class="widget-img" *ngIf="seriesSummary[0]?.tvpiIcon">
                        <img data-cy="tvpiIcon" src="{{seriesSummary[0]?.tvpiIcon}}"
                            [matTooltip]="seriesSummary[0]?.tvpiTitle" matTooltipClass="blue-tooltip" 
                            matTooltipPosition="right">
                    </div>
                </h2>
                <p class="card-label mr-26">Investment TVPI</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start ml-16 md:ml-5 mb-3 md:mb-0">
                <h2 class="card-value d-flex" data-cy="irr" [ngClass]="{'no-icon': !seriesSummary[0]?.irrIcon}">
                    {{seriesSummary[0]?.irr | irrValueFormat}}
                    <div class="widget-img" *ngIf="seriesSummary[0]?.irrIcon">
                        <img data-cy="irrIcon" src="{{seriesSummary[0]?.irrIcon}}" 
                            [matTooltip]="seriesSummary[0]?.irrTitle" matTooltipClass="blue-tooltip" 
                            matTooltipPosition="right">
                    </div>
                </h2>
                <p class="card-label mr-26">Investment IRR</p>
            </div>
        </div>
        <div class="series-card no-result-text bg-white" data-cy="seriesNoResult" *ngIf="noResultsSeries">
            {{highchartService.noResultsText}}</div>
    </div>
</div>
import { Injectable } from '@angular/core';
import superagent from 'superagent';
import { EnvironmentVariablesService } from './environment-variables.service';


/**
 * Axios has been replaced with SuperAgent (issue #28), to maintain some backwards
 * compatibility the SuperAgent response object is marshaled to conform
 * to the Axios response object
 */
function marshalHttpResponse(response) {
    const statusMessage = response.res ? response.res.statusMessage : '';
    return {
        data: response.body,
        status: response.status,
        statusText: response.statusText || statusMessage,
        headers: response.headers,
        request: response.xhr || response.req,
        config: response.req
    };
}

const DEFAULT_INTERVAL = 0;
const DEFAULT_BATCH = 0;
const NOOP = () => {};

@Injectable({
  providedIn: 'root'
})
export class SumologicService {

  config: any;
  pendingLogs: any;
  interval: any;
  logSending: any;

  constructor(private evService: EnvironmentVariablesService) {

        var opts = {
            clientUrl: this.evService.productionURL,
            endpoint: this.evService.sumoLogic['endPoint'],
            returnPromise: false,
            interval: this.evService.sumoLogic['interval'], 
            batchSize: this.evService.sumoLogic['batchSize'], 
            sendErrors: this.evService.sumoLogic['sendErrors'],      
            sourceName: this.evService.sumoLogic['sourceName'],
            sourceCategory: this.evService.sumoLogic['sourceCategory'],
            hostName: this.evService.sumoLogic['hostName'],      
            graphite: this.evService.sumoLogic['graphite'],
            sessionKey: this.getUUID(), // generate a GUID
            onSuccess: function() {
            // ... handle success ....
            console.log("_____ I have successfully added Log entry _____");
            },
            onError: function() {
            // ... handle error ....
            console.log("_____ I have FAILED added Log entry _____");
            }      
        };

        this.config = {};
        this.pendingLogs = [];
        this.interval = 0;
        this.logSending = false;
        this.setConfig(opts);
    }

    getUUID() {
        // eslint gets funny about bitwise
        /* eslint-disable */
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const piece = (Math.random() * 16) | 0;
            const elem = c === 'x' ? piece : (piece & 0x3) | 0x8;
            return elem.toString(16);
        });
        /* eslint-enable */
    }

    /**
    * Axios has been replaced with SuperAgent (issue #28), to maintain some backwards
    * compatibility the SuperAgent response object is marshaled to conform
    * to the Axios response object
    */
    marshalHttpResponse(response) {
        const statusMessage = response.res ? response.res.statusMessage : '';
        return {
            data: response.body,
            status: response.status,
            statusText: response.statusText || statusMessage,
            headers: response.headers,
            request: response.xhr || response.req,
            config: response.req
        };
    }

    setConfig(configParams) {
        this.config = {
            endpoint: configParams.endpoint,
            returnPromise: Object.prototype.hasOwnProperty.call(
                configParams,
                'returnPromise'
            )
                ? configParams.returnPromise
                : true,
            clientUrl: configParams.clientUrl || '',
            useIntervalOnly: configParams.useIntervalOnly || false,
            interval: configParams.interval || DEFAULT_INTERVAL,
            batchSize: configParams.batchSize || DEFAULT_BATCH,
            sourceName: configParams.sourceName || '',
            hostName: configParams.hostName || '',
            sourceCategory: configParams.sourceCategory || '',
            session: configParams.sessionKey || this.getUUID(),
            onSuccess: configParams.onSuccess || NOOP,
            onError: configParams.onError || NOOP,
            graphite: configParams.graphite || false,
            raw: configParams.raw || false
        };
    }

    batchReadyToSend() {
        if (this.config.batchSize === 0) {
            return this.config.interval === 0;
        } else {
            const pendingMessages = this.pendingLogs.reduce((acc, curr) => {
                const log = JSON.parse(curr);
                return acc + log.msg + '\n';
            }, '');
            const pendingBatchSize = pendingMessages.length;
            const ready = pendingBatchSize >= this.config.batchSize;
            if (ready) {
                this.stopLogSending();
            }
            return ready;
        }
    }

    _postSuccess(logsSentLength) {
        this.pendingLogs = this.pendingLogs.slice(logsSentLength);
        this.logSending = false;
        // Reset interval if needed:
        this.startLogSending();
        this.config.onSuccess();
    }

    sendLogs() {
        if (this.logSending || this.pendingLogs.length === 0) {
        return false;
        }

        try {
            this.logSending = true;

            const headers = {
                'X-Sumo-Client': 'sumo-javascript-sdk'
            };
            if (this.config.graphite) {
                Object.assign(headers, {
                    'Content-Type': 'application/vnd.sumologic.graphite'
                });
            } else {
                Object.assign(headers, { 'Content-Type': 'application/json' });
            }
            if (this.config.sourceName !== '') {
                Object.assign(headers, {
                    'X-Sumo-Name': this.config.sourceName
                });
            }
            if (this.config.sourceCategory !== '') {
                Object.assign(headers, {
                    'X-Sumo-Category': this.config.sourceCategory
                });
            }
            if (this.config.hostName !== '') {
                Object.assign(headers, { 'X-Sumo-Host': this.config.hostName });
            }

            if (this.config.returnPromise && this.pendingLogs.length === 1) {
                return superagent
                    .post(this.config.endpoint)
                    .set(headers)
                    .send(this.pendingLogs.join('\n'))
                    .then(marshalHttpResponse)
                    .then(res => {
                        this._postSuccess(1);
                        return res;
                    })
                    .catch(error => {
                        this.config.onError(error);
                        this.logSending = false;
                        return Promise.reject(error);
                    });
            }

            const logsToSend = Array.from(this.pendingLogs);
            return superagent
                .post(this.config.endpoint)
                .set(headers)
                .send(logsToSend.join('\n'))
                .then(marshalHttpResponse)
                .then(() => {
                    this._postSuccess(logsToSend.length);
                })
                .catch(error => {
                    this.config.onError(error);
                    this.logSending = false
                    if (this.config.returnPromise) {
                        return Promise.reject(error);
                    }
                })
        } catch (ex) {
            this.config.onError(ex);
            return false;
        }
    }

    startLogSending() {
        if (this.config.interval > 0) {
            if (this.interval) {
                this.stopLogSending();
            }
            this.interval = setInterval(() => {
                this.sendLogs();
            }, this.config.interval);
        }
    }

    stopLogSending() {
        clearInterval(this.interval);
    }


    emptyLogQueue() {
        this.pendingLogs = [];
    }

    flushLogs() {
        return this.sendLogs();
    }

    formatDate(date) {
        return date.toJSON();
    }

    log(msg, optionalConfig) {
        try{
            let message = msg;

            if (!message) {
                console.error('A value must be provided');
                return false;
            }
    
            const isArray = message instanceof Array;
            const testEl = isArray ? message[0] : message;
            const type = typeof testEl;
    
            if (type === 'undefined') {
                console.error('A value must be provided');
                return false;
            }
    
            
            if (
                this.config.graphite &&
                (!Object.prototype.hasOwnProperty.call(testEl, 'path') ||
                    !Object.prototype.hasOwnProperty.call(testEl, 'value'))
            ) {
                console.error(
                    'Both "path" and "value" properties must be provided in the message object to send Graphite metrics'
                );
                return false;
            }
            
    
            if (type === 'object') {
                if (Object.keys(message).length === 0) {
                    console.error('A non-empty JSON object must be provided');
                    return false;
                }
            }
    
            if (!isArray) {
                message = [message];
            }
    
            let ts = new Date();
            let sessKey = this.config.session;
            const client = { url: this.config.clientUrl };
    
            if (optionalConfig) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        optionalConfig,
                        'sessionKey'
                    )
                ) {
                    sessKey = optionalConfig.sessionKey;
                }
    
                if (
                    Object.prototype.hasOwnProperty.call(
                        optionalConfig,
                        'timestamp'
                    )
                ) {
                    ts = optionalConfig.timestamp;
                }
    
                if (Object.prototype.hasOwnProperty.call(optionalConfig, 'url')) {
                    client.url = optionalConfig.url;
                }
            }
    
            const timestamp = this.formatDate(ts);
    
            const messages = message.map(item => {
                if (this.config.graphite) {
                    return `${item.path} ${item.value} ${Math.round(
                        ts.getTime() / 1000
                    )}`;
                }
                if (this.config.raw) {
                    return item;
                }
                if (typeof item === 'string') {
                    return JSON.stringify(
                        Object.assign(
                            {
                                msg: item,
                                sessionId: sessKey,
                                timestamp
                            },
                            client
                        )
                    );
                }
                const current = {
                    sessionId: sessKey,
                    timestamp
                };
                return JSON.stringify(Object.assign(current, client, item));
            });
    
            this.pendingLogs = this.pendingLogs.concat(messages);
    
            if (!this.config.useIntervalOnly && this.batchReadyToSend()) {
                return this.sendLogs();
            }
        }
        catch(err){
            console.log('Error in sumologic log method',err);
        }
    }

}

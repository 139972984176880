import { Component, OnInit } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { TimeoutService } from '../services/timeout.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  error: any;
  description: any;
  logOutButtonText: any;
  continueButtonText: any;
  sessionImageUrl: any;
  headingText2: any;
  headingText1: any;

  constructor(private userService: UserService, public timeoutService: TimeoutService) { }

  ngOnInit(): void {
    this.getContentfulData();
  }

  logoutClick(): void {
    ////////// Logout //////////
    this.userService.logoutApp();
  }

  getContentfulData(): void {
    this.timeoutService.sessionPageContent$.subscribe((data) => {
      const result = JSON.parse(data);
      this.error = result.error;
      this.headingText1 = result.headingText1;
      this.headingText2 = result.headingText2;
      this.description = documentToHtmlString(result.description.json);
      this.logOutButtonText = result.logOutButtonText;
      this.continueButtonText = result.continueButtonText;
      this.sessionImageUrl = result.image.url;
    });
  }
}

<div class="session-wrap">
  <div class="session-container  justify-content-center align-items-center h-100">
    <div class="session-pod w-[90vw] h-[40vh] lg:w-[950px] md:h-[520px]">
      <div class="container  h-100">
        <div class="row grid grid-cols-1 md:grid-cols-3 h-100">
          <div class="hidden md:flex md:col-span-1 image  justify-content-center align-items-center h-100">
            <img class="max-w-[85%] lg:max-w-full"src="{{ sessionImageUrl }}" alt="session" />
          </div>
          <div class="md:col-span-2 session-content justify-content-center p-4 h-100">
            <div class="first-header text-3xl md:text-5xl lg:text-7xl">{{ headingText1 }} </div>
            <div class="second-header text-2xl md:text-4xl lg:text-6xl">{{timeoutService.sessionTimeLeft}} {{ headingText2 }}</div>
            <p class="text-lg md:text-2xl" [innerHTML]="description | safeHtml"></p>
            <div class="row pt-4">
              <div class="col">
                <button class="btn btn-outline" (click)="logoutClick();">{{logOutButtonText}}</button>
              </div>
              <div class="col">
                <button class="btn btn-primary" (click)="timeoutService.sessionContinueClick();">{{continueButtonText}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</div>
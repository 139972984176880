<div class="row" tourAnchor="asset.dashboard.summary">
    <div class="col-12">
        <div class="asset-summary widget-card card bg-white mx-0" *ngIf="assetData">
            <div class="row headersection grid grid-cols-1 gap-y-5 lg:grid-cols-12" *ngIf="!showingOnHoldingDashboard">
                <div class="col"
                    [ngClass]="showGallery && showingOnHoldingDashboard ? 'lg:col-span-7' : showGallery ? 'lg:col-span-5' : 'lg:col-span-12'">
                    <div *ngIf="showLogo" class="logo">
                        <img src="{{logo}}" data-cy="assetLogo">
                    </div>
                    <div>
                        <h4 class="summary-text">Summary</h4>
                    </div>
                    <div class="summary-desc">
                        <div *ngIf="showSummary">
                            <div [innerHTML]="summary | safeHtml" #summaryElement class="line-clamp text-md md:text-lg"
                                data-cy="assetSummary"
                                [ngClass]="{'block-ellipsis-with-logo': showLogo, 'block-ellipsis-without-logo' : !showLogo }">
                            </div>
                            <div *ngIf="showMoreButton">
                                <a href="javaScript:;" (click)="openModal()" data-cy="viewMoreButton"
                                    class="view-more-btn">View More</a>
                            </div>
                        </div>
                        <div *ngIf="!showSummary">
                            <div class="no-summary" data-cy="noSummaryText">No Asset Summary Available</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showGallery" [ngClass]="showingOnHoldingDashboard ? 'lg:col-span-5' : 'lg:col-span-7'"
                    class="px-[15px] lg:col-span-7">
                    <app-image-carousel [slidingImgs]="slidingImgs" data-cy="assetImages"></app-image-carousel>
                </div>
            </div>
            <div class="lg:grid lg:grid-cols-3 gap-2">
                <div [ngClass]="showGallery && showingOnHoldingDashboard ? 'col-span-2' : 'col-span-3'">
                    <span *ngIf="showingOnHoldingDashboard" class="text-md md:text-2xl font-bold">Asset Summary</span>
                    <div class="data-items row grid gap-x-3 grid-cols-2 md:grid-cols-5 h-100"
                        *ngIf="assetType === 'Infrastructure'" data-cy="infrastructureContainer">
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetSector">{{assetData.sector |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Sector</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="standardFormatValue">{{assetData.securityType
                                |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Security</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="standardFormatValue">{{assetData.stage |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Stage</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetStatus">{{assetData.status |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Status</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetIndustry">{{assetData.industry |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Industry</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="dateValue">{{assetData.initialInvestmentDate
                                |
                                dateFormat : 'MM/dd/yyyy'}}
                            </div>
                            <div class="title text-md md:text-lg">Initial Investment Date</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetRegion">{{assetData.region |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Region</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetCountry">{{assetData.country |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Country</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="emptyValue">{{assetData.sponsor |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Sponsor</div>
                        </div>
                    </div>
                    <div class="data-items row real-estate-row grid gap-x-3 grid-cols-2 md:grid-cols-4 h-100"
                        *ngIf="assetType === 'Real Estate'" data-cy="realEstateContainer">
                        <div class="col">
                            <div class="value text-md md:text-lg">{{assetData.invStatus | standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Investment Status</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="dateValue">{{assetData.initialInvestmentDate
                                |
                                dateFormat : 'MM/dd/yyyy'}}</div>
                            <div class="title text-md md:text-lg">Initial Investment Date</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg">{{assetData.geography | standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Geography</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="standardFormatValue">{{assetData.propertyType
                                |
                                standardStringFormat}}
                            </div>
                            <div class="title text-md md:text-lg">Property Type</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="standardFormatValue">{{assetData.sponsor |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Sponsor</div>
                        </div>
                        <div class="col">
                            <div class="left">
                                <div class="value text-right text-md md:text-lg" data-cy="emptyValue">
                                    {{assetData.squareFeet
                                    |
                                    standardStringFormat}}</div>
                                <div class="title text-right text-md md:text-lg">Square Feet</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="left">
                                <div class="value text-right text-md md:text-lg" data-cy="emptyValue">
                                    {{assetData.stories |
                                    standardStringFormat}}</div>
                                <div class="title text-right text-md md:text-lg"># of Stories</div>
                            </div>
                        </div>
                        <div class="col pr-150">
                            <div class="left">
                                <div class="value text-right text-md md:text-lg" data-cy="emptyValue">{{assetData.acres
                                    |
                                    standardStringFormat}}</div>
                                <div class="title text-right text-md md:text-lg">Acres</div>
                            </div>
                        </div>
                    </div>
                    <div class="data-items row portfolio-company-row grid gap-x-3 grid-cols-2 md:grid-cols-4 h-100"
                        *ngIf="assetType === 'Portfolio Company'" data-cy="portfolioCompanyContainer">
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetIndustry">{{assetData.industry |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Industry</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetSector">{{assetData.sector |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Sector</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetRegion">{{assetData.region |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Region</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="assetCountry">{{assetData.country |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Country</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="standardFormatValue">
                                {{assetData.fiscalYearEnd |
                                dateFormat : 'MM/dd/yyyy'}}
                            </div>
                            <div class="title text-md md:text-lg">Fiscal Year End</div>
                        </div>
                        <div class="col">
                            <div class="value text-md md:text-lg" data-cy="emptyValue">{{assetData.symbol |
                                standardStringFormat}}</div>
                            <div class="title text-md md:text-lg">Symbol</div>
                        </div>
                        <div class="col">
                            <div class="left">
                                <div class="value text-right text-md md:text-lg" data-cy="emptyValue">
                                    {{assetData.numberOfEmployees | standardStringFormat}}</div>
                                <div class="title text-right text-md md:text-lg"># of Employees</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="left">
                                <div class="value text-right text-md md:text-lg" data-cy="emptyValue">
                                    {{assetData.numberOfLocations | standardStringFormat}}</div>
                                <div class="title text-right text-md md:text-lg"># of Locations</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showGallery && showingOnHoldingDashboard">
                    <app-image-carousel [slidingImgs]="slidingImgs" data-cy="assetImages"></app-image-carousel>
                </div>
            </div>
        </div>
    </div>
</div>
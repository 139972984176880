import { Injectable } from '@angular/core';
import { environment as environmentConfig } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentVariablesService {

  oktaConfigs: any = {};
  productionURL: string;
  sumoLogic: any = {};
  // Get array of locations where we have to send logs
  loggingTargets: any = [];
  contentful: any;
  sessionStandAloneTime: any;
  proxy: any;
  googleMapsAPIKey: string;
  googleAnalyticsID: string;
  raygunApiKey: string;
  clientExperienceDataServiceUri: string;
  clientDocumentDownloadUri: string;
  holdingDateForHidingWidgets: string;
  notificationConfig: any = {};
  clientDocs: string;
  hideDatesPriorToDate: string;
  assetsFolderPath: string = '';

  constructor() {
    this.setEnvironmentConfig();
  }

  setEnvironmentConfig = () => {
    this.proxy = environmentConfig.proxy;
    this.contentful = environmentConfig.contentful;
    this.sessionStandAloneTime = environmentConfig.sessionStandAloneTime;
    this.productionURL = location.origin;
    this.googleMapsAPIKey = environmentConfig.googleMapsApiKey;
    this.googleAnalyticsID = environmentConfig.googleAnalyticsID;
    this.raygunApiKey = environmentConfig.raygunApiKey;
    this.oktaConfigs = environmentConfig.oktaConfigs;
    this.sumoLogic = environmentConfig.sumologic;
    this.loggingTargets = environmentConfig.loggingTargets;
    this.clientExperienceDataServiceUri = environmentConfig.clientExperienceDataServiceUri;
    this.clientDocumentDownloadUri= environmentConfig.clientDocumentDownloadUri;
    this.holdingDateForHidingWidgets = environmentConfig.holdingDateForHidingWidgets;
    this.notificationConfig = environmentConfig.notificationConfig;
    this.clientDocs = environmentConfig.clientDocs;
    this.hideDatesPriorToDate = environmentConfig.hideDatesPriorToDate;
    this.assetsFolderPath = environmentConfig.assetsFolderPath;
  }
}

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { EnvironmentVariablesService } from './environment-variables.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class ProxyService {
  cfBaseURL: string;
  oktaBaseURL: string;
  contentFulAuthToken: string;

  constructor(
    private http: HttpClient,
    private evService: EnvironmentVariablesService,
    private loggingService: LoggingService,
    private userService: UserService
  ) {
    this.cfBaseURL = this.evService.proxy['hostname'] + '/' + this.evService.proxy['apis'].Contentful;
    this.oktaBaseURL = this.evService.proxy['hostname'] + '/' + this.evService.proxy['apis'].Okta;
  }

  proxyErrorHandler(error: HttpErrorResponse) {
    this.loggingService.log(error.message + ' - Proxy Failed to respond. Application Logged out');
    this.userService.logoutApp()
    return throwError(() => error || "server error.");
  }

  updateTnC(mode): Observable<any> {
    let data = '{"termsAndConditionsAccepted": ' + mode + '}';
    let apiURL = this.oktaBaseURL + "/termsandconditions";
    return this.http.post(apiURL, data).pipe(catchError(x => this.proxyErrorHandler(x)))
  }
}

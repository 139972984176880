<app-base-widget>
    <mat-tab-group animationDuration="0" (selectedTabChange)="handleChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <div header class="d-flex">{{tabs[0]}}
                    <app-info-icon tooltipPosition="above"
                        tooltipInfo="Deal level commitment & NAV by Strategy Type"
                        data-cy="strategyTooltip">
                    </app-info-icon>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <app-base-widget [error]="strategyError" [card]="false" data-cy="strategyChart">
                    <gcm-chart [chartStyle]="chartStyle" chartType="bar" [chartOptions]="strategyChartOptions">
                    </gcm-chart>
                </app-base-widget>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div header class="d-flex">{{tabs[1]}}
                    <app-info-icon tooltipPosition="above" tooltipInfo="Deal level commitment & NAV by Investment Type"
                        data-cy="investmentTypeTooltip">
                    </app-info-icon>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <app-base-widget [error]="investmentTypeError" [card]="false" data-cy="investmentTypeChart">
                    <gcm-chart [chartStyle]="chartStyle" chartType="bar" [chartOptions]="investmentTypeChartOptions">
                    </gcm-chart>
                </app-base-widget>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div header class="d-flex">{{tabs[2]}}
                    <app-info-icon tooltipPosition="above" tooltipInfo="Deal level commitment & NAV by Vintage Year"
                        data-cy="vintageYearTooltip">
                    </app-info-icon>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <app-base-widget [error]="vintageYearError" [card]="false" data-cy="vintageYearChart">
                    <gcm-chart [chartStyle]="chartStyle" chartType="bar" [chartOptions]="vintageYearChartOptions">
                    </gcm-chart>
                </app-base-widget>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</app-base-widget>
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngb-modal-content',
  templateUrl: './ngb-modal-content.component.html',
  styleUrls: ['./ngb-modal-content.component.scss']
})
export class NgbModalContentComponent {
  @Input() bodyText;
  @Input() headerText;
  @Input() closeText;

  constructor(public activeModal: NgbActiveModal) {
  }
}

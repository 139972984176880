import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SumologicService } from './services/sumologic.service';
import { EnvironmentVariablesService } from './services/environment-variables.service';
import { Router } from '@angular/router';
import { ProxyService } from './services/proxy.service';
import { LoggingService } from './services/logging.service';

export function createApollo(httpLink: HttpLink, evService: EnvironmentVariablesService, loggingService: LoggingService): ApolloClientOptions<any> {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        loggingService.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) {
      const varnetworkError = JSON.parse(JSON.stringify(networkError));
      varnetworkError.error.errors.forEach(element => {
        loggingService.log(element.message);
      });
      loggingService.log(networkError.message);
    }
  });

  const auth = setContext((operation, context) => {
    return {
      headers: {
        Authorization: `Bearer ${evService.contentful['token']}`,
      },
    };
  });

  const uri = `https://graphql.contentful.com/content/v1/spaces/${evService.contentful['spaceId']}/environments/pre-auth`; // <-- add the URL of the GraphQL server here
  return {
    link: ApolloLink.from([errorLink, auth, httpLink.create({ uri })]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, EnvironmentVariablesService, SumologicService, Router, ProxyService],
    },
  ],
})
export class GraphQLModule { }

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-text-box',
  templateUrl: './info-text-box.component.html',
  styleUrls: ['./info-text-box.component.scss']
})
export class InfoTextBoxComponent {
  @Input() infoText;
}

import { Injectable } from '@angular/core';
import { ApiService } from '../core/http/api.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  constructor(private apiService: ApiService) { }

  fetchPortfolioMetadata() {
    return new Promise((resolve, reject) => {
      this.apiService.getPortfoliosMetadata().subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        }
      });
    })
  }

  fetchSeriesDashboardMetaData(seriesId: number, investorId: number) {
    return new Promise((resolve, reject) => {
      this.apiService.getPortfolioSeriesDashboardMetadata(seriesId, investorId).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        }
      });
    })
  }
}
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvironmentVariablesService } from "src/app/services/environment-variables.service";
import { Okta } from "src/app/shared/okta.service";

@Injectable()
export class OktaTokenAuthInterceptor implements HttpInterceptor {
    constructor(private evService: EnvironmentVariablesService,
        private okta: Okta) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let idToken = this.okta?.getOktaAuth()?.getIdToken();
        if (idToken && idToken != '') {
            const cfBaseURL = this.evService.proxy['hostname'] + '/' + this.evService.proxy['apis'].Contentful;
            const oktaBaseURL = this.evService.proxy['hostname'] + '/' + this.evService.proxy['apis'].Okta;
            let AuthRequest = request.clone();
            if (request.url.includes('api/')) {
                AuthRequest = request.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + idToken
                    }
                });
            }
            else if (request.url.includes(oktaBaseURL)) {
                AuthRequest = request.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + idToken,
                        'X-CustomOktaIssuer': 'true'
                    }
                });
            }
            else if (request.url.includes(cfBaseURL)) {
                AuthRequest = request.clone({
                    setHeaders: {
                        'Content-Type': 'text/plain',
                        'authorization': 'Bearer ' + idToken,
                        'X-CustomOktaIssuer': 'true'
                    }
                });
            }
            return next.handle(AuthRequest)
        } else {
            return next.handle(request);
        }
    }
}
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { asapScheduler } from "rxjs";
import { ActivityTrackingService } from '../services/activity-tracking.service';

@Component({
  selector: 'app-auto-adjusting-dropdown',
  templateUrl: './auto-adjusting-dropdown.component.html',
  styleUrls: ['./auto-adjusting-dropdown.component.scss']
})
export class AutoAdjustingDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('hiddenSelected') selectedEl: ElementRef;
  @ViewChild('selectList') selectEl: ElementRef;
  @Input() selected: string = ''
  @Input() dataForBreadcrumbs;
  @Input() queryParams: string = '';
  @Input() updateWidth: boolean = false;
  @Input() valueProperty: string;
  @Input() nameProperty: string;
  @Input() groupProperty: string;
  @Input() outerdivClass: string = '';
  @Input() optionClass: string = '';
  @Input() showOneOptGroup: boolean = false;
  @Input() optionClassProperty: string;
  @Input() selectClass: string;
  @Input() type: string;
  @Output() goToSelected: EventEmitter<any> = new EventEmitter();
  @Output() sendWidth = new EventEmitter<number>()
  width: number = 300;

  constructor(
    private activityTracking: ActivityTrackingService
  ) { }

  ngAfterViewInit(): void {
    if (this.selected && this.selectedEl?.nativeElement) {
      asapScheduler.schedule(() => {
        this.selectedEl.nativeElement.innerHTML = this.selected;
        this.getWidth()
      });
    }
  }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.selected && this.selectedEl?.nativeElement) {
      this.setHiddenBreadcrumbAndUpdateWidth();
    }
  }

  getWidth() {
    asapScheduler.schedule(() => {
      this.width = this.selectedEl.nativeElement.clientWidth + 20
    })
  }

  setHiddenBreadcrumbAndUpdateWidth() {
    asapScheduler.schedule(() => {
      var text = this.selectEl.nativeElement.options[this.selectEl.nativeElement.selectedIndex].innerHTML;
      this.selectedEl.nativeElement.innerHTML = text;
      this.getWidth();
    })
  }

  sendSelected(el): void {
    let activity = {
      name: `${this.type} dropdown`,
      contextParameter: [{'type': this.type}, {'id': el.value}, {'name': el.options[el.selectedIndex].text}]
    }
    this.activityTracking.userActivityStorage(activity);
    this.setHiddenBreadcrumbAndUpdateWidth();
    this.goToSelected.emit(el.value);
  }
}


<div class="row mt-4 grid grid-cols-1 lg:grid-cols-2">
    <div class="pr-30 pl-0" tourAnchor="holding.dashboard.largestPositions">
        <app-base-widget [error]="largestPositionsByRemainingValue?.error" data-cy="largestPositionWidget">
            <div header class="card-header d-flex">Largest Positions
                <app-info-icon tooltipPosition="above"
                    tooltipInfo="Top 5 largest unrealized asset positions for the Holding based on remaining value"
                    data-cy="largestPositionTooltip">
                </app-info-icon>
            </div>
            <span class="remaining-value-text">By Remaining Value</span>
            <gcm-chart [chartData]="largestPositionsByRemainingValue" [chartStyle]="chartStyle" chartType="funnel"
                [chartOptions]="largestPositionsByRemainingValueChartOptions"></gcm-chart>
        </app-base-widget>
    </div>
</div>
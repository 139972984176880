<div class="pl-0 lg:pl-[8.5rem] notfound-page w-full">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="logo">
          <img src="{{ gcmLogoUrl }}">
        </div>
        <div class="error-wrap">
          <img src="{{ errorImageUrl }}">

          <div class="pt-3 text-center">
            <h3>{{ pageTitle }}</h3>
            <p class="pt-2">{{ pageText }}</p>
          </div>
        </div>
        <div class="pt-4 text-center">
          <a routerLink="/dashboard" class="btn-link">
            {{goToDashboardButtonText}}
          </a>
        </div>
        <div class="pt-4 text-center email-link">
          <a href="mailto:{{mailToEmailAddress}}">{{emailAddressText}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SearchEngineService {

  constructor(@Inject(DOCUMENT) private dom, private metaService: Meta) { }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);

    this.setMetaTags();

  }

  setMetaTags(){
    this.metaService.addTags([
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'GCM Grosvenor' },
      { charset: 'UTF-8' }
    ]);
  }

}

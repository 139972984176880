import { Component, Input, ViewChild, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CsvModes } from 'src/app/core/csv-modes.class';
import { Asset } from 'src/app/models/classes/asset.class';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { ExportCsvService } from 'src/app/services/export-csv.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreadcrumbDataService } from 'src/app/services/breadcrumb-data.service';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
@Component({
  selector: 'app-assets-detail-table',
  templateUrl: './assets-detail-table.component.html',
  styleUrls: ['./assets-detail-table.component.scss']
})
export class AssetsDetailTableComponent {
  @Input() portfolioName: string = '';
  @Input() seriesName: string = '';
  @Input() holdingName: string = '';
  @Input() asOfDate: string = '';
  @Input() assets: Asset[] = [];
  @Input() currency: string = 'USD';
  expandView = false;
  displayedColumns: string[] = ['name', 'acquisitionDate', 'remainingCostBasis', 'remainingMarketValue', 'statementDate','sector', 'exitDate'];
  expandedDisplayedColumns: string [] = ['name', 'acquisitionDate', 'remainingCostBasis', 'remainingMarketValue', 'statementDate','sector', 'subIndustry', 'geography', 'country', 'status', 'exitDate'];
  columnsToDisplay: string[] = this.displayedColumns;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<Asset>;
  displayShadowOnAssetsTable: boolean = false;

  constructor(
    private exportCsvService: ExportCsvService,
    private dateFormatPipe: DateFormatPipe,
    public activatedRoute: ActivatedRoute,
    private breadcrumbDataService: BreadcrumbDataService,
    public activityTracking: ActivityTrackingService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.assets && this.assets) {
      this.dataSource = new MatTableDataSource(this.assets)
    }
  }

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  redirectToAssetPage(asset) {
    const params = this.activatedRoute.snapshot.params;
    return this.breadcrumbDataService.redirectToAssetsPage(asset, params);
  }

  exportAssetsDataToCsv(event: any) {
    this.activityTracking.exportToExcelClick('assets detail table', this.holdingName);
    event.target.disabled = true;
    let csvArray = [];
    const filename = this.exportCsvService.getExportFilename(CsvModes.holdingAssets, this.asOfDate, this.portfolioName, this.seriesName, this.holdingName);
    const csvHeader = this.exportCsvService.getCsvHeader(CsvModes.holdingAssets);
    csvArray.push(csvHeader);
    this.assets.forEach(asset => {
      let data = {};
      //Need to update value fields when we integrate API 
      data['assetName'] = asset.name;
      data['aquisitionDate'] = this.dateFormatPipe.transform(asset.acquisitionDate, 'M/d/yy');
      data['remainingCost'] = asset.remainingCostBasis;
      data['remainingMarketValue'] = asset.remainingMarketValue;
      data['statementDate'] = this.dateFormatPipe.transform(asset.mostRecentSoiDate, 'M/d/yy');
      data['sector'] = asset.sector;
      data['subIndustry'] = asset.subIndustry;
      data['geography'] = asset.mapRegion;
      data['country'] = asset.country;
      data['status'] = asset.status;
      data['exitDate'] = this.dateFormatPipe.transform(asset.exitDate, 'M/d/yy');
      csvArray.push(data);
    })
    const worksheets = [{name: 'Holding Assets', csvArray: csvArray}]
    this.exportCsvService.exportToExcelFile(filename, worksheets);
    event.target.disabled = false;
  }

  updateColumns(expandView) {
    this.expandView = expandView
    if (expandView) {
      this.columnsToDisplay = this.expandedDisplayedColumns;
    } else {
      this.columnsToDisplay = this.displayedColumns;
    }
    this.activityTracking.tableExpandViewToggle('assets detail table', this.holdingName, expandView)
  }

  scrollAssetsTableHandler(event) {
    if (event.target.scrollLeft > 0) {
      this.displayShadowOnAssetsTable = true;
    } else {
      this.displayShadowOnAssetsTable = false;
    }
  }
}

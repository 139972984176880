import { Component, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent {
  @Input() slidingImgs = new Array();

  constructor(
    carouselConfig: NgbCarouselConfig
  ) {
    carouselConfig.interval = 10000;
    carouselConfig.keyboard = true;
    carouselConfig.pauseOnHover = true;
  }
}

<div class="auth-error-page w-full">
    <div class="container">
        <div class="row">
            <div class="col static">
                <div class="logo-image h-full bottom-[calc(100vh-80px)] md:bottom-[calc(100vh-120px)]">
                    <div class="logo-img w-full h-full"></div>
                </div>
                <div class="image justify-content-center align-items-center h-full pt-[100px]">
                    <div class="error-img w-full h-full"></div>
                </div>
                <div class="error-wrap">
                    <div class="text-center pt-3">
                        <h3>User Not Authorized</h3>
                        <p class="pt-2">You are not permissioned to the site you are trying to access.</p>
                    </div>
                    <div class="pt-4 text-center" *ngIf="!hideDocsButton">
                        <a (click)="goToDocs()" class="btn-link">
                            GO TO DOCUMENTS
                        </a>
                    </div>
                    <div class="text-center pt-4 email-link">
                        <a href="mailto:{{mailToEmailAddress}}">{{emailAddressText}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="summary-modal" data-cy="modal">
    <div class="modal-header border-0 pt-4 px-5 pb-2">
        <div *ngIf="headerText">
            <h5 class="modal-title" data-cy="header">{{headerText}}</h5>
            <button type="button" class="close" (click)="activeModal.close('Close click')" aria-label=" Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body px-5 pt-3 pb-4" [innerHTML]="bodyText | safeHtml" data-cy="body"> </div>
    <div class="modal-footer border-0 popfooter">
        <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')" data-cy="closeButton">{{closeText}}</button>
    </div>
</div>
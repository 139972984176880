<div class="main-content mt-[65px] lg:mt-[82px]">
    <div *ngIf="iconSet" class="px-[5px] lg:pl-[9rem] pb-3 lg:pt-3">
        <div *ngIf="hasErrors; else showDetails" class="ml-3">
            <div *ngIf="showNoAccessError; else showErrorMessage">
                <app-no-access-error></app-no-access-error>
            </div>
            <ng-template #showErrorMessage>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 cumulative-graph">
                            <div>
                                <h3 style="text-align: center;">{{errorMessage}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template #showDetails>
            <div class="container">
                <div class="row">
                    <div class="md:mt-4 grid md:col-12 md:flex md:px-[15px] container__breadcrumb">
                        <div class="col-12 md:contents">
                            <div *ngFor="let breadcrumb of breadcrumbs" class="custom-breadcrumb">
                                <a class="custom-breadcrumb__text"
                                    routerLinkActive="active" [routerLink]="breadcrumb.route"
                                    [queryParams]="breadcrumbService.getQueryParamsForBreadcrumbs(breadcrumb)" [title]="breadcrumb.name" (click)="breadcrumbClick(breadcrumb)" data-cy="breadcrumbText">
                                    {{breadcrumb.name}}</a>
                                <mat-icon class="custom-breadcrumb__icon">chevron_right</mat-icon>
                            </div>
                            <app-auto-adjusting-dropdown [selected]="seriesNameForBreadcrumb"
                                [dataForBreadcrumbs]="portfolioDataForBreadcrumb"
                                (goToSelected)="goToSelectedSeries($event)" [queryParams]="queryParamsSeriesId"
                                (sendWidth)="getWidth($event)" [valueProperty]="'displayId'" 
                                [nameProperty]="'displayName'" [groupProperty]="'subEntities'"
                                [showOneOptGroup]="true" [type]="'Series'" data-cy="seriesDropdown"></app-auto-adjusting-dropdown>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex">
                            <div class="asofDates d-flex ml-auto">
                                <div>
                                    <img src="{{iconSet['icon.calendar']}}" class="asofDates__icon" />
                                </div>
                                <select name="asOfDateddl" class="asofDates__as-custom-select" id="asOfDateSel"
                                    [(ngModel)]="selectedAsOfDate" (change)='updateAsOfDate($event.target.value)'
                                    data-cy="asOfDateDropdown" [ngClass]="{'error-text' : isPortfolioSeriesDataUnavailable && valReportingDate}">
                                    <option *ngFor="let date of asOfDropdownData" [value]="date" 
                                        data-cy="selectedDate" class="normal-select-text"
                                        [disabled]="isPortfolioSeriesDataUnavailable && valReportingDate == date">
                                        {{date | dateFormat : 'MM/dd/yyyy'}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="!isPortfolioSeriesDataUnavailable">
                        <div class="snap-shot ml-auto mr-3">
                            <button (click)="getSnapshot();" tourAnchor="series.dashboard.snapshot" data-cy="seriesSnapshot"><img
                                    src="{{iconSet['icon.snapshot']}}" />Snapshot</button>
                        </div>
                    </div>
                </div>
                <ng-container>
                    <app-error-card *ngIf="isPortfolioSeriesDataUnavailable" data-cy="errorCard">
                        <h3 class="card-title">Series data for the selected reporting period <b>{{selectedAsOfDate | dateFormat : 'MM/dd/yyyy'}}</b> is not available.</h3>
                        <div>
                            <div>Please return to the prior page or select a new date from the As of Date Dropdown.</div>
                            <div>Click 
                                <a routerLinkActive="active" [routerLink]="breadcrumbs[0]?.route" (click)="breadcrumbClick(breadcrumbs[0])"
                                    [queryParams]="breadcrumbService.getQueryParamsForBreadcrumbs(breadcrumbs[0])" class="click-here-btn" data-cy="investorDashboardRedirect">
                                    here
                                </a>
                                to go back to the Investor dashboard.</div>
                        </div>
                    </app-error-card>
                    <app-series-dashboard-summary [portfolioSummary]="portfolioSummary" [iconSet]="iconSet"
                        [selectedDateObj]="valReportingDate"
                        [seriesSummary]="seriesSummary"></app-series-dashboard-summary>

                    <div class="grid grid-cols-1 lg:grid-cols-2 mt-4">
                        <div class="pr-4 lg:pr-7 pl-0 mln-15" tourAnchor="series.dashboard.commitment.widget">
                            <app-commitment-value-widget [widgets]="widgets"
                                [currency]="currency"></app-commitment-value-widget>
                        </div>
                        <div class="pr-4 pl-0 mt-4 lg:mt-0 mln-15 lg:ml-0"
                            tourAnchor="series.dashboard.remaining.widget">
                            <app-remaining-value-widget [widgets]="widgets" [iconSet]="iconSet"
                                [currency]="currency"></app-remaining-value-widget>
                        </div>
                    </div>
                    <app-key-performance-widget [widgets]="widgets" [currency]="currency"></app-key-performance-widget>
                    <app-series-holdings-table [iconSet]="iconSet" [investmentTypes]="investmentTypes"
                        [summary]="summary" [portfolioName]="portfolioName" [seriesName]="seriesName"
                        [asOfDate]="selectedAsOfDate" [currency]="currency"></app-series-holdings-table>

                    <app-series-qroll-table [iconSet]="iconSet" [portfolioSummary]="portfolioSummary" [summary]="summary"
                        [investmentTypes]="investmentTypes" [portfolioName]="portfolioName" [seriesName]="seriesName"
                        [asOfDate]="selectedAsOfDate"></app-series-qroll-table>
                </ng-container>
                
            </div>
        </ng-template>
    </div>
</div>